import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./client/apollo";
import { AuthContextProvider } from "./components/context/provider";
import { MyRoutes } from "./routes";

export default function HelpDesk({ appData }) {
    return (
        <ApolloProvider client={apolloClient}>
            <AuthContextProvider appData={appData}>
                <BrowserRouter>
                    <MyRoutes />
                </BrowserRouter>
            </AuthContextProvider>
        </ApolloProvider>
    );
}
