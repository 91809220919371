import React from 'react'
import {Text } from 'slate'
import escapeHtml from 'escape-html'

 export const Error = ({text}) => {
    return (
      <div className="rounded-md bg-red-50 mt-1 p-2"> 
          <svg className="h-5 w-5 text-red-400 inline mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
          </svg> 
          <span className="text-sm text-red-700">
            {text}
          </span>
      </div>
    )
  }


  function serialize(node) {
    
    if (Text.isText(node)) {
      if(node.italic) {
        return '<em>' + escapeHtml(node.text) + '</em>'
      } else if(node.bold) {
        return '<strong>' + escapeHtml(node.text) + '</strong>'
      } else {
        return escapeHtml(node.text)
      }
    }
    const map_obj = (Array.isArray(node)) ? node: node.children
    if(map_obj) {

      const children = map_obj.map(n => serialize(n)).join('')
 
      switch (node.type) {
        case 'quote':
          return `<blockquote><p>${children}</p></blockquote>`
        case 'paragraph':
          return `<p>${children}</p>`
        case 'numbered-list':
          return `<ol>${children}</ol>`
        case 'list-item':
          return `<li>${children}</li>`
        case 'heading-one':
          return `<h1>${children}</h1>`
        case 'heading-two':
          return `<h2>${children}</h2>`
        case 'link':
          return `<a href="${escapeHtml(node.url)}">${children}</a>`
        default:
          return children
        }
    } else {
        
        return node.children;
    
    }
} 

export default serialize;   