import { useContext } from "react";
import { ModalLayout } from "../layout/modal";
import { AuthContext } from "../context/context";

export const Info = (props) => {
    const text = props.text;
    const content = props.content;
    const title = props.title;

    const [authContext, dispatch] = useContext(AuthContext);

    function close() {
        dispatch({
            type: "CLEAR_MODAL",
        });
    }

    return (
        <>
            <ModalLayout title={title}>
                <div>
                    <div
                        dangerouslySetInnerHTML={{ __html: text }}
                        className=" mb-5  prose max-w-max overflow-x-auto "
                    ></div>
                    {content}
                </div>

                <div className=" py-4 flex items-center space-x-4 justify-end">
                    <button
                        type="button"
                        onClick={() => close()}
                        className="flex items-center   text-gray-600 h-12  px-10 py-1 focus:outline-none  space-x-4 bg-gray-100 rounded-md border border-gray-200 hover:bg-gray-200"
                    >
                        <span className="truncate">Zavřít</span>
                    </button>
                </div>
            </ModalLayout>
        </>
    );
};
