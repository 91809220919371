import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Logo } from "./layout/logo";
import { AuthContext } from "./context/context";
import { CURRENT_USER } from "../query/user";
import { useLazyQuery } from "@apollo/client";
import { axiosProtected, loggedUrl, CONFIG_APP_API_ROUTE } from "../client";
import BuildingList from "./buildings";

export default function LoginModal(props) {
    const setBuilding = props.setBuilding;
    const [authContext, dispatch] = useContext(AuthContext);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [resMessage, setResponse] = useState();

    const [getUserData] = useLazyQuery(CURRENT_USER, {
        onCompleted: (data) => {
            authContext.login(data.currentUser);
            return data;
        },
    });

    const building = authContext.building;

    function setBuildings(data) {
        dispatch({
            type: "SET_BUILDINGS",
            payload: {
                buildings: data.buildings,
                allBuildings: data.allBuildings,
            },
        });
    }

    const onSubmit = async (data) => {
        try {
            await setLoading(true);
            const res = await axios.post(CONFIG_APP_API_ROUTE + "login_check", {
                username: data.login,
                password: data.pass,
            });
            if (res.data) {
                localStorage.setItem(
                    "token",
                    JSON.stringify({
                        ...res.data,
                        expiration: new Date(
                            new Date().getTime() + 60 * 60 * 1000
                        ),
                    })
                );
                const response = await getUserData();
                let res2 = await axios.get(
                    loggedUrl +
                        "units/user_unit/" +
                        response?.data?.currentUser?.userData?._id +
                        "/",
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + res.data.token,
                        },
                    }
                );

                const length = res2.data.units.length;
                if (length === 1) {
                    await setBuilding(res2.data.units[0].units_alias);
                    dispatch({
                        type: "CLEAR_MODAL",
                    });
                } else {
                    if (building?.unit) {
                        let res3 = await axiosProtected().get(
                            "units/unit_data/" +
                                building?.unit?.units_alias +
                                "/"
                        );
                        await setBuilding(res3.data);
                        await setBuildings(res2.data.units);
                        await setLoading(false);
                        dispatch({
                            type: "CLEAR_MODAL",
                        });
                    } else {
                        dispatch({
                            type: "SET_MODAL",
                            payload: {
                                component: BuildingList,
                                params: {
                                    setBuildings: setBuildings,
                                    setBuilding: setBuilding,
                                    building: building,
                                },
                            },
                        });
                    }
                }
            }
        } catch (err) {
            console.log(err);
            setResponse(err?.response?.data?.message?.toString());
            setTimeout(() => {
                setResponse(false);
            }, 10000);
            setLoading(false);
        }
    };

    return (
        <>
            <div className=" flex-col justify-center  ">
                <div>
                    <div className="flex ">
                        <div className="hidden lg:flex lg:flex-wrap content-center lg:w-1/2 bg-cover bg-gradient-to-r from-green-800 to-gray-300 text-white ">
                            <svg
                                className="w-40 mx-auto opacity-30"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1}
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                />
                            </svg>
                        </div>
                        <div className="w-80 p-2 mx-auto lg:py-10 lg:pb-20 ">
                            <h2 className="text-2xl h-24 font-semibold text-blue-600 text-center py-5">
                                <Logo />
                            </h2>
                            {/*

                    <a href={API_URL + "login/password/google/"} onClick={() => setgLoading(true)}  className={"flex items-center justify-center mt-4 text-white rounded-lg shadow-md bg-gray-100 hover:bg-gray-200"}>
                        
                        {gloading 
                        ? ( <h1 className="px-4 py-3 pl-0 w-5/6 text-center text-gray-600 "> 
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Probíhá přesměrování</h1>)
                        : ( <>
                          <div className="px-4 py-3">
                          <svg className="h-6 w-6" viewBox="0 0 40 40">
                              <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#FFC107"/>
                              <path d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z" fill="#FF3D00"/>
                              <path d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z" fill="#4CAF50"/>
                              <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#1976D2"/>
                          </svg>
                      </div>
                      <h1 className="px-4 py-3 pl-0 pr-12 w-5/6 text-center text-gray-600 ">Přihlášení Google</h1> </>)
                        }
                        
                    </a>
                    <div className="mt-4 flex items-center justify-between">
                        <span className="border-b w-1/5 lg:w-1/4"></span>
                        <span className="text-xs text-center text-gray-500 ">nebo pomocí e-mailu</span>
                        <span className="border-b w-1/5 lg:w-1/4"></span>
                    </div>
                       */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mt-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">
                                        E-mail
                                    </label>
                                    <input
                                        type="text"
                                        {...register("login", {
                                            required: true,
                                        })}
                                        className=" text-gray-700 focus:outline-none focus:shadow-outline focus:ring-green-500 focus:border-green-500 border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                                    />
                                    {errors.login && (
                                        <div className="rounded-md bg-red-50 mt-1 p-2">
                                            <svg
                                                className="h-5 w-5 text-red-400 inline mr-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <span className="text-sm text-red-700">
                                                Vyplňte přihlašovací jméno
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="mt-4">
                                    <div className="flex justify-between">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            Heslo
                                        </label>
                                    </div>
                                    <input
                                        type="password"
                                        {...register("pass", {
                                            required: true,
                                        })}
                                        className="  text-gray-700 focus:outline-none focus:shadow-outline focus:ring-green-500 focus:border-green-500 border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                                    />
                                    {errors.pass && (
                                        <div className="rounded-md bg-red-50 mt-1 p-2">
                                            <svg
                                                className="h-5 w-5 text-red-400 inline mr-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <span className="text-sm text-red-700">
                                                Vyplňte heslo
                                            </span>
                                        </div>
                                    )}
                                </div>
                                {resMessage && (
                                    <div className="rounded-md bg-red-50 mt-2 p-2">
                                        <svg
                                            className="h-5 w-5 text-red-400 inline mr-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <span className="text-sm text-red-700">
                                            {resMessage}
                                        </span>
                                    </div>
                                )}
                                <div className="mt-8">
                                    {loading ? (
                                        <button
                                            disabled
                                            className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600"
                                        >
                                            <svg
                                                className="animate-spin -ml-1 mr-3 h-5 w-5 inline"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                ></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                ></path>
                                            </svg>
                                            <span>Přihlašování...</span>
                                        </button>
                                    ) : (
                                        <button className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600">
                                            Přihlásit
                                        </button>
                                    )}
                                </div>
                            </form>
                            <div className="mt-4 flex items-center justify-between"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
