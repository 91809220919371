import React from "react"; 
import { Title } from "../layout/Title"
import Userdata from "./signup/Userdata"


export const SignUp = () => {
    return (
        <>
        <div className="grid lg:grid-cols-2 gap-5 p-5 lg:p-8 bg-gray-100 bg-opacity-90 ">
            <div>

                <h3 className="text-lg text-gray-500 md:text-xl mx-auto  mb-4">
                    <Title bold={'Registrace'} /> 
                </h3>
                <div  className="bg-white px-4 py-6 shadow sm:p-6 rounded-md my-2 relative border  " > 
                    <Userdata />
                </div>
            </div>
        </div>
        </>
    )
}