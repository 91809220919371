import { Transition } from "@headlessui/react";
import { default as Logo } from "../../images/symbol.svg";
import { default as Oval } from "../../images/oval.svg";

export const Loading = ({ loading }) => {
    //backdrop-filter backdrop-blur-sm
    return (
        <Transition
            show={loading}
            className="fixed z-[1000] top-[40%] left-[42%] lg:left-[50%]  overflow-y-auto   "
        >
            <div className="flex flex-wrap  items-end overflow-y-auto   text-center sm:block sm:p-0  ">
                {/* <Transition.Child
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                    enter="ease-out duration-50"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-50"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                     <div className="absolute top-0 bottom-0 inset-0 bg-gray-100 opacity-60 h-full  w-full " /> 
                </Transition.Child> */}

                <span
                    className={"hidden sm:inline-block sm:align-middle "}
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <Transition.Child
                    className="inline-block align-bottom  rounded-lg  text-left overflow-hidden  transform transition-all sm:align-top sm:max-w-4xl w-full h-full   "
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                    enter="ease-out duration-50"
                    enterFrom="opacity-0 "
                    enterTo="opacity-100 translate-y-0 "
                    leave="ease-in duration-50"
                    leaveFrom="opacity-100 translate-y-0 "
                    leaveTo="opacity-0   "
                >
                    <div className="flex flex-1 flex-wrap   py-5 text-white  w-full h-full items-center ">
                        <div className="h-full w-full text-center flex flex-col justify-center items-center  ">
                            <div className=" bg-green-700 rounded-full shadow-lg relative">
                                <img
                                    src={Logo}
                                    className="w-10 h-10 absolute top-3 left-3"
                                />
                                <img src={Oval} className="w-16 h-16  " />
                            </div>
                        </div>
                    </div>
                </Transition.Child>
            </div>
        </Transition>
    );
};
