import { Menu, Transition } from "@headlessui/react";
import { AuthContext } from "../context/context";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { CogIcon } from "@heroicons/react/outline";

export default function User(props) {
    const user = props.user;
    const building = props.building;
    const setOpen = props.setOpen;

    const [authContext] = useContext(AuthContext);

    const logoutHandler = () => {
        authContext.logout();
    };

    return (
        <>
            <Menu>
                {({ open }) => (
                    <>
                        {user ? (
                            <Menu.Button
                                className={
                                    "hidden lg:flex max-w-xs h-12 text-green-100 bg-white bg-opacity-10  rounded-full  items-center text-sm focus:outline-none  lg:p-2 lg:rounded-md lg:hover:bg-green-800" +
                                    (open
                                        ? " bg-green-800 bg-opacity-100 "
                                        : "")
                                }
                                id="user-menu"
                            >
                                <div className="flex items-center">
                                    <div className="  mx-2">
                                        {user.img ? (
                                            <img
                                                src={user.img}
                                                className="h-8 w-8 fill-current border-2  rounded-full"
                                                alt=""
                                            />
                                        ) : (
                                            <svg
                                                className="h-8"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={1}
                                                    d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                        )}
                                    </div>

                                    <span className="hidden  text-sm font-medium lg:block">
                                        <span className="sr-only">
                                            Uživatelské menu pro{" "}
                                        </span>
                                        {user.surname ? (
                                            <>
                                                {user.fname} {user.surname}
                                            </>
                                        ) : (
                                            <>{user.email}</>
                                        )}
                                    </span>
                                    <svg
                                        className="hidden flex-shrink-0 ml-1 h-5 w-5  lg:block"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </div>
                            </Menu.Button>
                        ) : (
                            <></>
                        )}

                        <Transition
                            className="origin-top-right z-40 absolute top-10 right-0 mt-2 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                            show={open}
                            enter="transition ease-out duration-100 transform"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="transition ease-in duration-75 transform"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Menu.Items
                                static
                                className="divide-y divide-gray-100 outline-none"
                            >
                                <div className="px-4 py-3">
                                    <p className="text-sm leading-5">E-mail</p>
                                    <p className="text-sm font-medium leading-5  truncate">
                                        {user && user.email}
                                    </p>
                                </div>

                                {building &&
                                    building.unit &&
                                    building.unit.groups_description && (
                                        <div className="py-1">
                                            <span className="text-gray-800 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left">
                                                {
                                                    building.unit
                                                        .groups_description
                                                }
                                            </span>
                                        </div>
                                    )}

                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <NavLink
                                                to={"/nastaveni"}
                                                className={`${
                                                    active
                                                        ? "bg-gray-100 text-gray-900"
                                                        : "text-gray-800"
                                                } flex w-full px-4 py-2 text-sm leading-5 text-left space-x-2 items-center`}
                                            >
                                                <CogIcon className="w-6" />
                                                <span>Nastavení</span>
                                            </NavLink>
                                        )}
                                    </Menu.Item>

                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${
                                                    active
                                                        ? "bg-gray-100 text-gray-900"
                                                        : "text-gray-800"
                                                } flex w-full px-4 py-2 text-sm leading-5 mt-5 text-left`}
                                                onClick={logoutHandler}
                                            >
                                                <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 511.996 511.996"
                                                    className="h-5 w-5 mr-2 inline"
                                                    fill="currentColor"
                                                    stroke="none"
                                                >
                                                    <path
                                                        d="M349.85,62.196c-10.797-4.717-23.373,0.212-28.09,11.009c-4.717,10.797,0.212,23.373,11.009,28.09
                                                        c69.412,30.324,115.228,98.977,115.228,176.035c0,106.034-85.972,192-192,192c-106.042,0-192-85.958-192-192
                                                        c0-77.041,45.8-145.694,115.192-176.038c10.795-4.72,15.72-17.298,10.999-28.093c-4.72-10.795-17.298-15.72-28.093-10.999
                                                        C77.306,99.275,21.331,183.181,21.331,277.329c0,129.606,105.061,234.667,234.667,234.667
                                                        c129.592,0,234.667-105.068,234.667-234.667C490.665,183.159,434.667,99.249,349.85,62.196z"
                                                    />
                                                    <path
                                                        d="M255.989,234.667c11.782,0,21.333-9.551,21.333-21.333v-192C277.323,9.551,267.771,0,255.989,0
                                                        c-11.782,0-21.333,9.551-21.333,21.333v192C234.656,225.115,244.207,234.667,255.989,234.667z"
                                                    />
                                                </svg>
                                                <span>Odhlásit</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </>
                )}
            </Menu>

            <div className=" lg:hidden">
                <div className="flex  items-center px-5">
                    <div className="flex-shrink-0">
                        {user?.img ? (
                            <img
                                src={user.img}
                                className="h-10 w-10 fill-current border-2  rounded-full"
                                alt=""
                            />
                        ) : (
                            <svg
                                className="h-10"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1}
                                    d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        )}
                    </div>
                    <div className="ml-3 min-w-0 flex-1">
                        <div className="text-base font-medium text-gray-800 truncate">
                            {user?.fname} {user?.surname}
                        </div>
                        <div className="text-sm font-medium text-gray-500 truncate">
                            {user?.email}
                        </div>
                    </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                    <div className="py-1">
                        <NavLink
                            to={"/nastaveni"}
                            onClick={() => setOpen(false)}
                            className="  text-gray-800 flex w-full px-4 py-2 text-sm leading-5 text-left space-x-2 items-center"
                        >
                            <CogIcon className="w-6 h-6" />
                            <span>Nastavení</span>
                        </NavLink>
                        <button
                            className="text-gray-800 flex w-full px-4 py-2 text-sm leading-5 text-left  space-x-2 items-center mt-3"
                            onClick={logoutHandler}
                        >
                            <svg
                                version="1.1"
                                id="Layer_1"
                                className="w-6 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 511.996 511.996"
                                fill="currentColor"
                                stroke="none"
                            >
                                <path
                                    d="M349.85,62.196c-10.797-4.717-23.373,0.212-28.09,11.009c-4.717,10.797,0.212,23.373,11.009,28.09
                                                        c69.412,30.324,115.228,98.977,115.228,176.035c0,106.034-85.972,192-192,192c-106.042,0-192-85.958-192-192
                                                        c0-77.041,45.8-145.694,115.192-176.038c10.795-4.72,15.72-17.298,10.999-28.093c-4.72-10.795-17.298-15.72-28.093-10.999
                                                        C77.306,99.275,21.331,183.181,21.331,277.329c0,129.606,105.061,234.667,234.667,234.667
                                                        c129.592,0,234.667-105.068,234.667-234.667C490.665,183.159,434.667,99.249,349.85,62.196z"
                                />
                                <path
                                    d="M255.989,234.667c11.782,0,21.333-9.551,21.333-21.333v-192C277.323,9.551,267.771,0,255.989,0
                                                        c-11.782,0-21.333,9.551-21.333,21.333v192C234.656,225.115,244.207,234.667,255.989,234.667z"
                                />
                            </svg>
                            <span>Odhlásit</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
