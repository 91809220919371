import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { Job } from "./Job";

export const Select = ({ items, selectedJobs, setSelectedJobs }) => {
  const [open, setOpen] = useState(false);

  function countSelected() {
    items.job.forEach((element) => {
      if (selectedJobs.includes(element.alias)) {
        count++;
      }
    });
    return count;
  }
  let count = 0;
  count = countSelected();

  return (
    <>
      <button
        type="button"
        className={
          "px-3 h-12 text-gray-600 border border-gray-300 items-center text-sm focus:outline-none  lg:p-2  rounded-md lg:hover:bg-gray-200 w-full " +
          (open ? " bg-gray-200 " : "") +
          (count > 0 ? " bg-green-700 bg-opacity-10 text-green-700" : "")
        }
        onClick={() => setOpen(!open)}
        id="user-menu"
      >
        <div className="flex items-center justify-between relative">
          {count > 0 && (
            <span className="flex absolute h-6 w-6 top-0 right-0 -mt-5 -mr-5">
              <span className="relative inline-flex items-center justify-center rounded-full h-5 w-5 text-xs text-white  font-bold  bg-green-700">
                {count}
              </span>
            </span>
          )}
          <div className={"  mx-2 truncate " + (count > 0 && "font-medium")}>
            {items.name}
          </div>

          <svg
            className="flex-shrink-0 ml-1 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      {open && (
        <div
          className="absolute lg:fixed inset-0 h-full z-50   "
          onClick={() => setOpen(false)}
        />
      )}
      <Transition
        className="lg:origin-top-left z-50 fixed flex flex-wrap items-end  pb-10 lg:pb-0 inset-0 lg:right-auto lg:top-auto lg:bottom-auto lg:left-auto lg:absolute  backdrop-filter backdrop-blur-sm h-full lg:h-auto lg:backdrop-blur-none lg:mt-2 lg:w-56 lg:rounded-md lg:shadow-lg  "
        show={open}
        enter="transition ease-out duration-50 transform"
        enterFrom="opacity-0 translate-y-4 lg:translate-y-0 lg:scale-95"
        enterTo="opacity-100 translate-y-0 lg:scale-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          className="absolute lg:hidden inset-0 bg-black opacity-20 h-full  "
          onClick={() => setOpen(false)}
        />
        <div className="relative w-full px-3 lg:px-0">
          <div className="absolute right-4 mx-auto  z-50 -mt-12 lg:hidden">
            <button
              type="button"
              className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200   text-gray-100  "
              onClick={() => setOpen(false)}
            >
              <span className="sr-only">Close sidebar</span>

              <svg
                className="h-6 w-6 "
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="overflow-hidden  sm:align-middle w-full  border border-gray-400 bg-white rounded-lg lg:mx-0 lg:-mt-1  relative ">
            <div className="px-5 py-5 lg:px-0 lg:py-0">
              <div className="text-lg lg:hidden py-3 mb-5 font-medium border-b">
                {items.name}
              </div>
              <div className=" grid">
                {items.job &&
                  items.job.length > 0 &&
                  items.job.map((job, jobIdx) => (
                    <Job
                      job={job}
                      key={jobIdx}
                      selectedJobs={selectedJobs}
                      setSelectedJobs={setSelectedJobs}
                    />
                  ))}
              </div>
              <div className="  flex justify-end lg:hidden ">
                <button
                  type="button"
                  className="bg-green-700 text-white mb-6 py-2 px-14  rounded hover:bg-green-600"
                  onClick={() => setOpen(false)}
                >
                  Potvrdit
                </button>
              </div>
            </div>
            <div className="  text-left  transform transition-all  "></div>
          </div>
        </div>
      </Transition>
    </>
  );
};
