import { SearchIcon } from "@heroicons/react/solid";
import React, { useState, useCallback, useEffect } from "react";
import { axiosProtected } from "../../../client";
import { UserCard } from "../../layout/UserCard";

export async function userSearch(search) {
  return axiosProtected()
    .post("logbook/userdata_search/", {
      query: search,
    })
    .then((res) => res.data);
}

export const UserSearch = ({ workers, setWorkers, register, setValue }) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  async function submit(event) {
    const search = event.target.value;
    if (search) {
      setLoading(true);
      const searchResults = await userSearch(search);
      await setResults(searchResults);
      setLoading(false);
    } else {
      setResults([]);
    }
  }

  useEffect(() => {
    setResults([]);
    setValue("search");
    //eslint-disable-next-line
  }, [workers]);

  const debounce = (func, debounceTimeout) => {
    let timeout;
    return function executedFunction(...args) {
      const delayedFunction = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(delayedFunction, debounceTimeout);
    };
  };

  // eslint-disable-next-line
  const debouncedSubmit = useCallback(
    debounce((event) => {
      submit(event);
    }, 700),
    []
  );

  return (
    <>
      <div className="relative ">
        <label htmlFor="search" className="sr-only">
          Vyhledávání
        </label>
        <div className="relative max-w-xl">
          <input
            autoComplete="off"
            id="search"
            {...register("search")}
            onChange={debouncedSubmit}
            name="search"
            className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400   focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
            placeholder="Hledat"
            type="search"
          />
          <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
            <SearchIcon className="h-5 w-5 text-gray-400" />
          </div>
          <div className="pointer-events-none absolute inset-y-0 right-14 pr-5 flex items-center">
            {loading && (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 inline"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </div>
        </div>

        {results.length > 0 && (
          <div className="min-w-full table-auto mt-0.5  absolute z-50">
            <div className="bg-white border border-gray-300 shadow-lg overflow-hidden rounded-md">
              <ul>
                {results.map((user, userIdx) => (
                  <button
                    type="button"
                    className="block border-b w-full"
                    key={userIdx}
                    onClick={() => setWorkers(user)}
                  >
                    <UserCard user={user} />
                  </button>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
