import axios from "axios";

let url
if(process.env.NODE_ENV==='development') {
  url = 'http://localhost/stephanos/admin/upload/files/'
} else {
  url = 'https://admin.stephanos.cz/upload/files/'
}

export default axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json",
  },
});