import React, { useState, useMemo, useContext, useEffect } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import HelpdeskLayout from "./helpdesk";
import moment from "moment";
import "moment/locale/cs";
import { SubTitle } from "../layout/Title";
import { AuthContext } from "../context/context";
import { useQuery } from "@apollo/client";
import { HELPDESK_LIST } from "../../query/helpdesk";
import { HelpdeskItem } from "./helpdeskItem";
import { Pagination } from "../layout/pagination";
import { Loading } from "../layout/Loading";

export default function ListItems(props) {
    let { typ, page } = useParams();
    let navigate = useNavigate();
    const building = props.building;

    const [thisOnly, setThisOnly] = useState();
    const [done, setDone] = useState(0);
    const [privateArray, setPrivateArray] = useState([0]);

    const [authContext, dispatch] = useContext(AuthContext);
    const isAdmin = authContext.admin;
    let userGroup = authContext.building?.group;

    useEffect(() => {
        dispatch({ type: "SET_TITLE", payload: { title: "Správa a údržba" } });
    }, []);

    if (!page) {
        page = 1;
    }
    if (!typ) {
        typ = "aktivni";
    }

    const pagination = 30;
    const { loading, data } = useQuery(HELPDESK_LIST, {
        variables: {
            unitId: parseInt(building?.unit?.units_id),
            //vyrizuje: authContext?.userData?._id,
            private: privateArray,
            done: done,
            page: parseInt(page),
            order: { vlozeno: "desc" },
        },
        fetchPolicy: "cache-and-network",
    });

    const { data: privateData } = useQuery(HELPDESK_LIST, {
        variables: {
            unitId: parseInt(building?.unit?.units_id),
            userId: authContext?._id,
            private: [1],
            done: done,
        },
    });

    const helpList = data?.helpdesks?.collection || [];
    const totalCount = data?.helpdesks?.paginationInfo?.totalCount;

    const privateList = privateData?.helpdesks?.collection || [];

    useMemo(() => {
        if (isAdmin) {
            setPrivateArray([]);
        } else {
            if (userGroup === "vybor") {
                setPrivateArray([0, 2]);
            } else {
                setPrivateArray([0]);
            }
        }
        if (typ === "vyresene") {
            setDone(1);
        } else if (typ === "moje") {
            setDone(0);
        } else if (typ === "odlozene") {
            setDone(2);
        } else {
            setDone(0);
        }
        // eslint-disable-next-line
    }, [typ, building, authContext]);

    useEffect(() => {
        if (typ === "vyresene") {
            dispatch({
                type: "SET_TITLE",
                payload: { title: "Vyřešené: požadavky" },
            });
        } else if (typ === "moje") {
            dispatch({
                type: "SET_TITLE",
                payload: { title: "Moje: požadavky" },
            });
        } else if (typ === "odlozene") {
            dispatch({
                type: "SET_TITLE",
                payload: { title: "Odložené: požadavky" },
            });
        } else {
            dispatch({
                type: "SET_TITLE",
                payload: { title: "Aktivní: požadavky" },
            });
        }
        // eslint-disable-next-line
    }, [typ]);

    function filter() {
        setThisOnly(!thisOnly);

        let items = building.helpdesk.my;
        items = items.filter((item) => {
            let unitName = item.alias;
            return unitName.indexOf(building.unit.units_alias) !== -1;
        });

        //thisOnly ? setHelpList(building.helpdesk.my) : setHelpList(items);
        //let result = cartList.filter(t=>t.id < 120);
    }

    const handlePageClick = (e) => {
        let url = "/helpdesk";
        const selectedPage = e.selected + 1;
        if (typ) {
            url = url + "/" + typ;
        }

        if (selectedPage === 1) {
            navigate(url);
        } else {
            navigate(url + "/" + selectedPage);
        }
    };

    return (
        <>
            {loading && <Loading loading={loading} />}
            <HelpdeskLayout {...props}>
                {typ === "moje" && building && isAdmin && (
                    <>
                        <div
                            className="flex items-center sm:col-span-4 cursor-pointer my-2"
                            onClick={() => filter()}
                        >
                            <button
                                type="button"
                                aria-pressed="false"
                                aria-labelledby="toggleLabel"
                                className={
                                    (thisOnly
                                        ? "bg-green-600"
                                        : "bg-gray-200") +
                                    " relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "
                                }
                            >
                                <span className="sr-only">
                                    pouze {building.unit.units_name}
                                </span>
                                <span
                                    aria-hidden="true"
                                    className={
                                        (thisOnly
                                            ? "translate-x-5"
                                            : "translate-x-0") +
                                        " pointer-events-none  inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                    }
                                ></span>
                            </button>
                            <span className="ml-3" id="toggleLabel">
                                <span
                                    className={
                                        (thisOnly
                                            ? "text-gray-900 font-medium"
                                            : "text-gray-400") + " text-sm  "
                                    }
                                >
                                    pouze {building.unit.units_name}
                                </span>
                            </span>
                        </div>
                    </>
                )}

                {((typ !== "moje" && helpList.length === 0) ||
                    (typ === "moje" &&
                        helpList.length === 0 &&
                        (!building.helpdesk.my_all ||
                            (building.helpdesk.my_all.todo.length === 0 &&
                                building.helpdesk.my_all.done.length ===
                                    0)))) && (
                    <>
                        <ul
                            id="gallery"
                            className="flex flex-1 flex-wrap my-10"
                        >
                            <li
                                id="empty"
                                className="h-full w-full text-center flex flex-col justify-center items-center  "
                            >
                                <svg
                                    className="w-32 mx-auto text-gray-300"
                                    fill="currentColor"
                                    version="1.1"
                                    id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        d="M510.194,221.846L441.735,84.353c-2.922-5.862-8.905-9.567-15.452-9.567H85.717c-6.547,0-12.53,3.705-15.452,9.567
                                    L1.806,221.846C0.616,224.233,0,226.868,0,229.537v190.418c0,9.532,7.726,17.258,17.258,17.258h477.483
                                    c9.532,0,17.258-7.726,17.258-17.258V229.537C512,226.868,511.384,224.233,510.194,221.846z M96.4,109.303h319.195l51.562,103.551
                                    H355.524c-8.158,0-15.216,5.782-16.902,13.761c-0.616,2.917-15.872,71.381-85.498,71.381s-84.883-68.464-85.487-71.312
                                    c-1.628-8.048-8.704-13.83-16.913-13.83H44.843L96.4,109.303z M477.483,402.697H34.517V247.371h103.108
                                    c12.127,35.339,46.644,85.142,115.499,85.142c68.861,0,103.378-49.802,115.499-85.142h108.86V402.697z"
                                    />
                                </svg>

                                <span className="text-small text-gray-400">
                                    Žádné položky
                                </span>
                            </li>
                        </ul>
                    </>
                )}
                {typ === "moje" && helpList.length > 0 && (
                    <>
                        <SubTitle title={"Přiřazené"} />
                    </>
                )}
                <div className=" divide-y divide-gray-200">
                    <div className="mt-2 divide-y divide-gray-200">
                        {helpList?.map((item, itemIdx) => (
                            <HelpdeskItem key={itemIdx} item={item} />
                        ))}
                    </div>
                </div>

                {typ === "moje" && (
                    <>
                        {typ === "moje" &&
                            building.helpdesk.my_all.todo.length > 0 && (
                                <>
                                    <SubTitle title={"Moje aktivní"} />
                                </>
                            )}
                        <div className="pt-6 divide-y divide-gray-200">
                            <div className="mt-2 divide-y divide-gray-200">
                                {building.helpdesk.my_all.todo.map(
                                    (item, itemIdx) => (
                                        <HelpdeskItem
                                            key={itemIdx}
                                            item={item}
                                        />
                                    )
                                )}
                            </div>
                        </div>

                        {typ === "moje" &&
                            building.helpdesk.my_all.done.length > 0 && (
                                <>
                                    <SubTitle title={"Moje vyřešené"} />
                                    <div className="pt-6 divide-y divide-gray-200">
                                        <div className="mt-2 divide-y divide-gray-200">
                                            {building.helpdesk.my_all.done.map(
                                                (item) => (
                                                    <HelpdeskItem
                                                        key={item.id}
                                                        item={item}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                    </>
                )}
                {totalCount > pagination && (
                    <Pagination
                        page={page}
                        totalCount={totalCount}
                        pagination={pagination}
                        handlePageClick={handlePageClick}
                    />
                )}
            </HelpdeskLayout>
        </>
    );
}
