import ReactPaginate from "react-paginate";

export const Pagination = ({
    page,
    totalCount,
    handlePageClick,
    pagination,
}) => {
    let pageCount = Math.ceil(totalCount / pagination);

    return (
        <div className="px-4 py-10 grid items-center justify-items-center mt-3  sm:px-6">
            <ReactPaginate
                previousLabel={
                    <>
                        <>
                            <svg
                                className="h-5 w-5 inline"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="inline-flex sm:hidden">
                                Předchozí
                            </span>
                        </>
                    </>
                }
                nextLabel={
                    <>
                        <span className="inline-flex sm:hidden">
                            Následující
                        </span>
                        <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </>
                }
                previousClassName={
                    "relative rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none flex-1 flex justify-between " +
                    (page === 1 && "hidden")
                }
                previousLinkClassName={
                    " px-2 py-2  focus:outline-none  inline-flex items-center"
                }
                nextClassName={
                    "relative  rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none " +
                    (page === 1 && "rounded-l-md lg:rounded-l-none ") +
                    (page === pageCount && " hidden")
                }
                nextLinkClassName={
                    "px-2 py-2 focus:outline-none inline-flex items-center"
                }
                breakLabel={"..."}
                breakClassName={
                    "relative  sm:inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                }
                pageCount={pageCount}
                forcePage={page - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={
                    "relative z-0 inline-flex  rounded-md shadow-sm -space-x-px select-none "
                }
                subContainerClassName={""}
                pageClassName={
                    "hidden sm:inline-flex relative    border border-gray-300 bg-white text-sm font-medium  hover:bg-gray-50 "
                }
                pageLinkClassName={"px-4 py-2 text-gray-700 focus:outline-none"}
                activeClassName={"bg-green-200 hover:bg-green-300 "}
            />
        </div>
    );
};
