import { CheckIcon } from "@heroicons/react/solid";
import React from "react";

export const Job = ({ job, selectedJobs, setSelectedJobs }) => {
  const selected = selectedJobs.includes(job.alias);

  return (
    <>
      <div
        className={`relative cursor-default select-none  py-3 pl-12 pr-4 hover:bg-green-700 hover:bg-opacity-10  ${
          selected
            ? "bg-green-700 bg-opacity-10 text-green-700 "
            : "text-gray-900"
        }`}
        value={job}
        onClick={() => setSelectedJobs(job.alias)}
      >
        <span
          className={`block truncate ${
            selected ? "font-medium" : "font-normal"
          }`}
        >
          {job.name}
        </span>
        {selected ? (
          <>
            <span className="absolute inset-y-0 left-2 top-0.5 flex items-center pl-3 text-gray-400 group-hover:text-green-700 ">
              <svg viewBox="0 0 50 50" className="  h-4 w-4 ">
                <rect
                  x="1"
                  y="1"
                  width="48"
                  height="48"
                  fill="none"
                  strokeWidth={3}
                  stroke="currentColor"
                />
              </svg>
            </span>
            <span className="absolute inset-y-0 left-1.5 flex items-center pl-3 text-green-600">
              <CheckIcon className="h-6 w-6" aria-hidden="true" />
            </span>
          </>
        ) : (
          <span className="absolute inset-y-0 left-2 top-0.5 flex items-center pl-3 text-gray-400 group-hover:text-green-700 ">
            <svg viewBox="0 0 50 50" className="  h-4 w-4 ">
              <rect
                x="1"
                y="1"
                width="48"
                height="48"
                fill="none"
                strokeWidth={3}
                stroke="currentColor"
              />
            </svg>
          </span>
        )}
      </div>
    </>
  );
};
