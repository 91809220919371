import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Error } from "../form/form";
import HelpdeskLayout from "./helpdesk";
import moment from "moment";
import "moment/locale/cs";
import FileUpload from "../fileUpload";
import Finish from "../helpdesk/finish";
import { axiosProtected } from "../../client";
import { Loading } from "../layout/Loading";
import { FileList } from "../layout/FileList";
import { File } from "../facility/documents/Files";
import { HELPDESK_DETAIL } from "../../query/helpdesk";
import { useLazyQuery } from "@apollo/client";
import { AuthContext } from "../context/context";

export default function HelpdeskDetail({
    setModal,
    building,
    userData,
    setModalContent,
    setModalIsOpen,
    setBuilding,
}) {
    let { pozadavek } = useParams();

    const [uploadId, setId] = useState();
    const [submittedData] = useState({});
    const [loading, setLoading] = useState(true);
    const [isOn, setIsOn] = useState(false);
    const user = userData;
    const [authContext, dispatch] = useContext(AuthContext);
    const isAdmin = authContext.admin;
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { isSubmitSuccessful, errors },
    } = useForm();

    const [item, setItem] = useState();

    const inserted = moment(item?.vlozeno).format("D. M. YYYY");
    const done = moment(item?.last_update).format("D. M. YYYY");

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({ ...submittedData });
        }
    }, [isSubmitSuccessful, submittedData, reset]);

    const onSubmit = async (data) => {
        setLoading(true);
        await axiosProtected()
            .post("helpdesk/comment/", data)
            .then((res) => {
                helpdeskSearch();
                setId(false);
                setLoading(false);
                setIsOn(false);
                helpdeskSearch();
                reset();
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    function finish() {
        dispatch({
            type: "SET_MODAL",
            payload: {
                component: Finish,
                params: {
                    item: item,
                    building: building,
                    setBuilding: setBuilding,
                },
            },
        });
    }

    async function postpone() {
        setLoading(true);
        try {
            const res = await axiosProtected().get(
                "helpdesk/postpone/" +
                    pozadavek +
                    "/" +
                    building?.unit?.units_id +
                    "/"
            );
            if (res.data) {
                await helpdeskSearch();
                dispatch({
                    type: "UPDATE_COUNT",
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (item) {
            let active = null;
            if (item.done === 0) {
                active = "aktivni";
            }
            if (item.done === 1) {
                active = "vyresene";
            }
            if (item.done === 2) {
                active = "odlozene";
            }
            if (item.private === 1) {
                active = "moje";
            }

            dispatch({
                type: "NAVIGATION_ACTIVE",
                payload: {
                    active: active,
                },
            });
        }
    }, [item]);

    const [helpdeskSearch] = useLazyQuery(HELPDESK_DETAIL, {
        variables: isAdmin
            ? {
                  number: pozadavek,
              }
            : {
                  number: pozadavek,
                  unitId: parseInt(building?.unit?.units_id),
              },
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            let response = data?.detail?.collection[0];
            if (
                (response?.private === 1 &&
                    (isAdmin ||
                        response.userId === authContext?.userData?.userId)) ||
                response?.private !== 1
            ) {
                setItem(data?.detail?.collection[0]);
            } else {
                navigate("/");
            }
            setLoading(false);
        },
    });

    /*
  const fetchData = useCallback(async () => {
    setLoading(true);
    const res = await axiosProtected().get(
      "helpdesk/detail/" + pozadavek + "/" + building?.unit?.units_id + "/"
    );
    if (res.data) {
      setItem(res.data);
    }
    setLoading(false);
    // eslint-disable-next-line
  }, [pozadavek]);
*/
    useEffect(() => {
        helpdeskSearch();
    }, [pozadavek, authContext.helpdesk.count]);

    useEffect(() => {
        if (!uploadId && isOn) {
            const id =
                "upload_" +
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15);
            setId(id);
        }
        setValue("uploadId", uploadId);
        // eslint-disable-next-line
    }, [uploadId, isOn]);

    return (
        <>
            <HelpdeskLayout building={building}>
                {item ? (
                    <>
                        <div className="w-full ">
                            <div className="grid  border-b border-gray-200 pb-2">
                                <h3 className="text-lg font-medium md:text-2xl   ">
                                    {item?.nazev}
                                </h3>
                                <div className="flex justify-end space-x-4 items-start">
                                    {item.done === 0 && authContext.admin && (
                                        <button
                                            onClick={() => finish()}
                                            className="inline-flex items-center px-4 py-2 border text-white border-green-400 rounded-md shadow-sm text-sm font-medium hover:bg-green-600 focus:outline-none bg-green-500"
                                        >
                                            Mám hotovo
                                        </button>
                                    )}
                                    {item.done === 0 &&
                                        (item.vyrizuje === user.data?.id ||
                                            authContext.admin) && (
                                            <button
                                                onClick={() => postpone()}
                                                className="inline-flex items-center px-4 py-2 border text-white border-blue-400 rounded-md shadow-sm text-sm font-medium hover:bg-blue-600 focus:outline-none bg-blue-500"
                                            >
                                                Odložit
                                            </button>
                                        )}
                                    {(item.done === 2 || item.done === 1) &&
                                        (item.vyrizuje === user.data?.id ||
                                            authContext.admin) && (
                                            <button
                                                onClick={() => postpone()}
                                                className="inline-flex items-center px-4 py-2 border text-white border-gray-400 rounded-md shadow-sm text-sm font-medium hover:bg-gray-600 focus:outline-none bg-gray-500"
                                            >
                                                Zpět do aktivních
                                            </button>
                                        )}
                                </div>
                            </div>

                            <div className="grid grid-cols-3 lg:grid-cols-7 text-sm w-full gap-4 py-4 border-b border-gray-200 items-center">
                                <div className="">
                                    <span
                                        className={
                                            "font-medium " +
                                            (item.done === 0 &&
                                                "text-green-600")
                                        }
                                    >
                                        # {item.number}
                                    </span>
                                </div>
                                <div>
                                    <span className="flex items-start w-full justify-items-start">
                                        <span className="w-6 flex items-start">
                                            {item.private === 1 ? (
                                                <svg
                                                    className="w-4 mr-1 pt-1 inline text-red-700"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    title="viditelné pouze výboru"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                                    />
                                                </svg>
                                            ) : (
                                                ""
                                            )}
                                            {item.private === 2 ? (
                                                <svg
                                                    className="w-4 mr-1 pt-1 inline text-blue-700"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    title="soukromý požadavek"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                                    />
                                                </svg>
                                            ) : (
                                                ""
                                            )}
                                        </span>

                                        {item.typ}
                                    </span>
                                </div>

                                <div className=" ">
                                    <span className="flex items-start w-full  ">
                                        <svg
                                            className="w-4 mr-1 pt-1 inline text-gray-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                            />
                                        </svg>
                                        {inserted}
                                    </span>
                                </div>

                                <div className="  ">
                                    {item?.userData?.userData && (
                                        <span>
                                            {item?.userData?.userData?.fname +
                                                " " +
                                                item?.userData?.userData
                                                    ?.surname}
                                        </span>
                                    )}
                                </div>
                                <div className="  ">
                                    <span>{item.unitData?.name}</span>
                                </div>

                                <div className="col-span-2 justify-end">
                                    {item.done === 1 ? (
                                        <>
                                            <span className="flex items-start w-full justify-center">
                                                <svg
                                                    className="w-4 mr-1 pt-1 inline text-gray-500"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    />
                                                </svg>
                                                {done}
                                            </span>
                                        </>
                                    ) : (
                                        <div className="bg-gray-100 px-1 py-1 rounded-md flex items-start">
                                            <svg
                                                className="w-4 mr-2 pt-1 inline"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                                />
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                />
                                            </svg>
                                            Vyřizuje{" "}
                                            {item.vyrizujeData?.fname +
                                                " " +
                                                item.vyrizujeData?.surname}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="flow-root py-3">
                                <ul className=" divide-y divide-gray-200 ">
                                    <li>
                                        <div className="relative py-5">
                                            <div className="relative flex items-start space-x-3">
                                                <div className="relative">
                                                    {item?.userData?.userData
                                                        ?.img ? (
                                                        <img
                                                            className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                                                            src={
                                                                item?.userData
                                                                    ?.userData
                                                                    ?.img
                                                            }
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <>
                                                            <div className="h-10 w-10 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                                                <svg
                                                                    className="h-7 w-7 text-gray-500"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="min-w-0 flex-1">
                                                    <div>
                                                        <div className="text-sm">
                                                            <span className="font-medium text-gray-900">
                                                                {item?.userData
                                                                    ?.userData && (
                                                                    <span>
                                                                        {item
                                                                            ?.userData
                                                                            ?.userData
                                                                            ?.fname +
                                                                            " " +
                                                                            item
                                                                                ?.userData
                                                                                ?.userData
                                                                                ?.surname}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <p className="mt-0.5 text-sm text-gray-500">
                                                            {moment(
                                                                item.vlozeno
                                                            ).format("LLL")}
                                                        </p>
                                                    </div>
                                                    <div className="mt-2 text-sm text-gray-700">
                                                        <div
                                                            className="whitespace-pre-line"
                                                            dangerouslySetInnerHTML={{
                                                                __html: item.text,
                                                            }}
                                                        ></div>

                                                        {item?.documents &&
                                                            item?.documents
                                                                ?.length >
                                                                0 && (
                                                                <div className="my-2 text-sm border-t pt-3">
                                                                    {item?.documents.map(
                                                                        (
                                                                            file,
                                                                            itemIdx
                                                                        ) => {
                                                                            return (
                                                                                <File
                                                                                    key={
                                                                                        itemIdx
                                                                                    }
                                                                                    file={
                                                                                        file
                                                                                    }
                                                                                />
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            )}

                                                        {item?.images &&
                                                            item?.images
                                                                ?.length >
                                                                0 && (
                                                                <FileList
                                                                    files={
                                                                        item?.images
                                                                    }
                                                                    setModalContent={
                                                                        setModalContent
                                                                    }
                                                                    setModalIsOpen={
                                                                        setModalIsOpen
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {item?.commentsData?.map(
                                        (comment, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className="relative py-5">
                                                        <div className="relative flex items-start space-x-3">
                                                            <div className="relative">
                                                                {comment
                                                                    ?.userData
                                                                    ?.img ? (
                                                                    <img
                                                                        className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                                                                        src={
                                                                            comment
                                                                                .userData
                                                                                ?.img
                                                                        }
                                                                        alt=""
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <div className="h-10 w-10 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                                                            <svg
                                                                                className="h-7 w-7 text-gray-500"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 20 20"
                                                                                fill="currentColor"
                                                                                aria-hidden="true"
                                                                            >
                                                                                <path
                                                                                    fillRule="evenodd"
                                                                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                                                                    clipRule="evenodd"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className="min-w-0 flex-1">
                                                                <div>
                                                                    <div className="text-sm">
                                                                        <span className="font-medium text-gray-900">
                                                                            {comment.userData
                                                                                ? comment
                                                                                      .userData
                                                                                      .fname +
                                                                                  " " +
                                                                                  comment
                                                                                      .userData
                                                                                      .surname
                                                                                : comment.nickname}
                                                                        </span>
                                                                    </div>
                                                                    <p className="mt-0.5 text-sm text-gray-500">
                                                                        {moment(
                                                                            comment.postTime
                                                                        ).format(
                                                                            "LLL"
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div className="mt-2 text-sm text-gray-700">
                                                                    <div
                                                                        className="whitespace-pre-line"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: comment.message,
                                                                        }}
                                                                    ></div>
                                                                    {comment?.documents &&
                                                                        comment
                                                                            ?.documents
                                                                            ?.length >
                                                                            0 && (
                                                                            <div className="my-2 text-sm border-t pt-3">
                                                                                {comment?.documents.map(
                                                                                    (
                                                                                        file,
                                                                                        itemIdx
                                                                                    ) => {
                                                                                        return (
                                                                                            <File
                                                                                                key={
                                                                                                    itemIdx
                                                                                                }
                                                                                                file={
                                                                                                    file
                                                                                                }
                                                                                            />
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        )}

                                                                    {comment?.images &&
                                                                        comment
                                                                            ?.images
                                                                            ?.length >
                                                                            0 && (
                                                                            <FileList
                                                                                files={
                                                                                    comment?.images
                                                                                }
                                                                                setModalContent={
                                                                                    setModalContent
                                                                                }
                                                                                setModalIsOpen={
                                                                                    setModalIsOpen
                                                                                }
                                                                            />
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        }
                                    )}

                                    <li>
                                        <div className="relative py-5">
                                            <div className="relative flex items-start space-x-3">
                                                <div className="relative">
                                                    {user?.img ? (
                                                        <img
                                                            className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                                                            src={user.img}
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <>
                                                            <div className="h-10 w-10 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                                                <svg
                                                                    className="h-7 w-7 text-gray-500"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="min-w-0 flex-1">
                                                    <div>
                                                        <form
                                                            onSubmit={handleSubmit(
                                                                onSubmit
                                                            )}
                                                        >
                                                            {item && (
                                                                <input
                                                                    type="hidden"
                                                                    {...register(
                                                                        "item_id"
                                                                    )}
                                                                    name="item_id"
                                                                    defaultValue={
                                                                        item?._id
                                                                    }
                                                                />
                                                            )}
                                                            <input
                                                                type="hidden"
                                                                {...register(
                                                                    "unit_id"
                                                                )}
                                                                name="unit_id"
                                                                defaultValue={
                                                                    item?.unit_id
                                                                }
                                                            />
                                                            <input
                                                                type="hidden"
                                                                {...register(
                                                                    "number"
                                                                )}
                                                                name="number"
                                                                defaultValue={
                                                                    item?.number
                                                                }
                                                            />
                                                            <div className="relative max-w-xl group">
                                                                <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        isOn
                                                                            ? setIsOn(
                                                                                  false
                                                                              )
                                                                            : setIsOn(
                                                                                  true
                                                                              )
                                                                    }
                                                                    className={
                                                                        "p-1  border rounded-full absolute top-2 right-2 focus:outline-none " +
                                                                        (isOn
                                                                            ? "bg-green-500 text-white border-green-600 "
                                                                            : "bg-gray-200 text-gray-600 border-gray-400 ")
                                                                    }
                                                                    title="Přidat přílohu"
                                                                >
                                                                    <svg
                                                                        className="w-5  "
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                            d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                                <textarea
                                                                    {...register(
                                                                        "message",
                                                                        {
                                                                            required: true,
                                                                        }
                                                                    )}
                                                                    className="border-1 bg-gray-50 border-gray-300 flex-grow  h-24 px-3 py-1 pr-6 w-full rounded-lg focus:border-gray-300 focus:placeholder-gray-200 focus:ring-2 focus:outline-none  focus:ring-green-500"
                                                                    name="message"
                                                                    placeholder="Napište komentář"
                                                                ></textarea>
                                                                {errors.name && (
                                                                    <Error text="Komentář nelze odeslat prázdný" />
                                                                )}
                                                                {isOn && (
                                                                    <>
                                                                        <FileUpload
                                                                            uploadId={
                                                                                uploadId
                                                                            }
                                                                            setModal={
                                                                                setModal
                                                                            }
                                                                            module={
                                                                                "comments"
                                                                            }
                                                                        />
                                                                        <input
                                                                            type="hidden"
                                                                            {...register(
                                                                                "uploadId"
                                                                            )}
                                                                            name="uploadId"
                                                                            defaultValue={
                                                                                uploadId
                                                                            }
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className="mt-2">
                                                                {loading ? (
                                                                    <button
                                                                        disabled
                                                                        className="bg-gray-200 text-gray-800 font-bold py-2 px-10 w-full lg:w-auto rounded cursor-default"
                                                                    >
                                                                        <svg
                                                                            className="animate-spin -ml-1 mr-3 h-5 w-5 inline"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                        >
                                                                            <circle
                                                                                className="opacity-25"
                                                                                cx="12"
                                                                                cy="12"
                                                                                r="10"
                                                                                stroke="currentColor"
                                                                                strokeWidth="4"
                                                                            ></circle>
                                                                            <path
                                                                                className="opacity-75"
                                                                                fill="currentColor"
                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                            ></path>
                                                                        </svg>
                                                                        <span>
                                                                            Odesílání...
                                                                        </span>
                                                                    </button>
                                                                ) : (
                                                                    <>
                                                                        <button
                                                                            type="submit"
                                                                            className="bg-gray-700 text-white font-bold py-2 px-10 w-full lg:w-auto rounded hover:bg-gray-600"
                                                                        >
                                                                            Přidat
                                                                            komentář
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="pt-6 divide-y divide-gray-200"></div>
                    </>
                ) : (
                    <></>
                )}
            </HelpdeskLayout>
            <Loading loading={loading} />
        </>
    );
}
