

export default function ConfirmDialog(props) {
  const { open, onClose, title, onConfirm, func } = props; 

  if (!open) {
      return <></>;
  }
  return(
      <main className="absolute top-0 right-0 left-0 bottom-0 w-screen h-screen antialiased   text-gray-900 font-sans overflow-x-hidden">
          <div className="relative px-4 min-h-screen md:flex md:items-center md:justify-center">
            <div className="bg-black opacity-25 w-full h-full absolute z-10 inset-0"></div>
            <div className="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative">
              <div className="md:flex items-center">
                <div className="flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto text-gray-600">
                  <svg className="w-20 h-20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                  <p className="font-bold">{title}</p>
                  <p className="text-sm text-gray-700 mt-1">
                  </p>
                </div>
              </div>
              <div className="text-center md:text-right mt-4 md:flex md:justify-end">
                <button onClick={() => onConfirm(func)} className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">Potvrdit</button>
                <button onClick={() => onClose()} className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4 focus:outline-none
                  md:mt-0 md:order-1 hover:bg-gray-300 focus:bg-gray-400">Storno</button>
              </div>
            </div>
          </div>
        </main>
  )

  
}