import { CONFIG_APP_API_ROUTE } from "../client";
import axios from "axios";

export async function refreshToken(logout) {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
        try {
            const res = await axios.post(
                CONFIG_APP_API_ROUTE + "token/refresh",
                {
                    refresh_token: token?.refresh_token,
                }
            );
            if (res.data) {
                localStorage.setItem(
                    "token",
                    JSON.stringify({
                        ...res.data,
                        expiration: new Date(
                            new Date().getTime() + 60 * 60 * 1000
                        ),
                    })
                );
            } else {
                typeof logout === "function" && logout();
            }
        } catch (err) {
            typeof logout === "function" && logout();
            console.log(err);
        }
    }
}
