import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Error } from "../form/form";
import LogbookLayout from "./logbook-layout";
import moment from "moment";
import "moment/locale/cs";
import { axiosProtected } from "../../client";
import { Loading } from "../layout/Loading";
import { FileList } from "../layout/FileList";
import { File } from "../facility/documents/Files";
import { HELPDESK_DETAIL } from "../../query/helpdesk";
import { useLazyQuery } from "@apollo/client";
import { AuthContext } from "../context/context";
import { LOGBOOK_DETAIL } from "../../query/logbook";

function toHoursAndMinutes(totalMinutes) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    let result;
    if (hours > 0) {
        result = hours + " h ";
    }
    if (minutes > 0) {
        result = result + minutes + " min ";
    }
    return result;
}
export default function LogbookDetail({
    setModal,
    building,
    userData,
    setModalContent,
    setModalIsOpen,
    setBuilding,
}) {
    let { zaznam } = useParams();

    const [uploadId, setId] = useState();
    const [submittedData] = useState({});
    const [loading, setLoading] = useState(true);
    const [isOn, setIsOn] = useState(false);
    const user = userData;
    const [authContext, dispatch] = useContext(AuthContext);
    const isAdmin = authContext.admin;
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { isSubmitSuccessful, errors },
    } = useForm();

    const [item, setItem] = useState();

    const inserted = moment(item?.vlozeno).format("D. M. YYYY");
    const done = moment(item?.last_update).format("D. M. YYYY");

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({ ...submittedData });
        }
    }, [isSubmitSuccessful, submittedData, reset]);

    useEffect(() => {
        if (item) {
            dispatch({
                type: "NAVIGATION_ACTIVE",
                payload: {
                    active: item.typ,
                },
            });
        }
    }, [item]);

    const [logbookDetail] = useLazyQuery(LOGBOOK_DETAIL, {
        variables: {
            number: zaznam,
            unitId: parseInt(building?.unit?.units_id),
        },
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            let response = data?.detail?.collection[0];
            if (
                (response.private === 1 &&
                    (isAdmin ||
                        response.userId === authContext?.userData?.userId)) ||
                response.private !== 1
            ) {
                setItem(data?.detail?.collection[0]);
            } else {
                navigate("/");
            }
            setLoading(false);
        },
    });

    useEffect(() => {
        logbookDetail();
    }, [zaznam, authContext.helpdesk.count]);

    useEffect(() => {
        if (!uploadId && isOn) {
            const id =
                "upload_" +
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15);
            setId(id);
        }
        setValue("uploadId", uploadId);
        // eslint-disable-next-line
    }, [uploadId, isOn]);

    return (
        <>
            <LogbookLayout building={building}>
                {item ? (
                    <>
                        <div className="w-full ">
                            <div className="grid  border-b border-gray-200 pb-2">
                                <h3 className="text-lg font-medium md:text-2xl   ">
                                    {item?.nazev}
                                </h3>
                            </div>

                            <div className="grid grid-cols-3 lg:grid-cols-7 text-sm w-full gap-4 py-4 border-b border-gray-200 items-start">
                                <div className="">
                                    <span
                                        className={
                                            "font-medium " +
                                            (item.done === 0 &&
                                                "text-green-600")
                                        }
                                    >
                                        # {item.number}
                                    </span>
                                </div>

                                <div className=" ">
                                    <span className="flex items-start w-full  ">
                                        <svg
                                            className="w-4 mr-1 pt-1 inline text-gray-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                            />
                                        </svg>
                                        {inserted}
                                    </span>
                                </div>

                                <div className=" col-span-3 ">
                                    <div className="  ">
                                        {item?.workers &&
                                            item?.workers.map(
                                                (worker, workerIdx) => (
                                                    <div key={workerIdx}>
                                                        {worker?.userData && (
                                                            <div className="w-full grid grid-cols-2">
                                                                <span>
                                                                    {worker
                                                                        ?.userData
                                                                        ?.fname +
                                                                        " " +
                                                                        worker
                                                                            ?.userData
                                                                            ?.surname}
                                                                </span>
                                                                <span>
                                                                    {toHoursAndMinutes(
                                                                        worker.hours *
                                                                            60 +
                                                                            worker.minutes
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                    </div>
                                </div>

                                <div className="col-span-2 justify-end">
                                    {item?.jobs &&
                                        item?.jobs.map((job, jobIdx) => (
                                            <div key={jobIdx}>
                                                <div className="flex">
                                                    <span>
                                                        {job.workName}
                                                        {": "}
                                                        {job.jobName}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>

                            <div className="flow-root py-3">
                                <ul className=" divide-y divide-gray-200 ">
                                    <li>
                                        <div className="relative py-5">
                                            <div className="relative flex items-start space-x-3">
                                                <div className="relative">
                                                    {item?.userData?.userData
                                                        ?.img ? (
                                                        <img
                                                            className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                                                            src={
                                                                item?.userData
                                                                    ?.userData
                                                                    ?.img
                                                            }
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <>
                                                            <div className="h-10 w-10 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                                                <svg
                                                                    className="h-7 w-7 text-gray-500"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="min-w-0 flex-1">
                                                    <div>
                                                        <div className="text-sm">
                                                            <span className="font-medium text-gray-900">
                                                                {item?.userData
                                                                    ?.userData && (
                                                                    <span>
                                                                        {item
                                                                            ?.userData
                                                                            ?.userData
                                                                            ?.fname +
                                                                            " " +
                                                                            item
                                                                                ?.userData
                                                                                ?.userData
                                                                                ?.surname}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <p className="mt-0.5 text-sm text-gray-500">
                                                            {moment(
                                                                item.vlozeno
                                                            ).format("LLL")}
                                                        </p>
                                                    </div>
                                                    <div className="mt-2 text-sm text-gray-700">
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: item.text,
                                                            }}
                                                        ></div>
                                                        <div></div>

                                                        {item?.documents &&
                                                            item?.documents
                                                                ?.length >
                                                                0 && (
                                                                <div className="my-2 text-sm border-t pt-3">
                                                                    {item?.documents.map(
                                                                        (
                                                                            file,
                                                                            itemIdx
                                                                        ) => {
                                                                            return (
                                                                                <File
                                                                                    key={
                                                                                        itemIdx
                                                                                    }
                                                                                    file={
                                                                                        file
                                                                                    }
                                                                                />
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            )}

                                                        {item?.images &&
                                                            item?.images
                                                                ?.length >
                                                                0 && (
                                                                <FileList
                                                                    files={
                                                                        item?.images
                                                                    }
                                                                    setModalContent={
                                                                        setModalContent
                                                                    }
                                                                    setModalIsOpen={
                                                                        setModalIsOpen
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="pt-6 divide-y divide-gray-200"></div>
                    </>
                ) : (
                    <></>
                )}
            </LogbookLayout>
            <Loading loading={loading} />
        </>
    );
}
