import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { refreshToken } from "../services/refreshToken";

import { CONFIG_APP_API_ROUTE } from "./index";

const httpLink = new HttpLink({ uri: CONFIG_APP_API_ROUTE + "graphql" });

const authMiddleware = () =>
    new ApolloLink(async (operation, forward) => {
        const token = JSON.parse(localStorage.getItem("token"));
        if (token) {
            operation.setContext({
                headers: {
                    authorization: `Bearer ${token.token}`,
                },
            });
        }

        return forward(operation);
    });

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const { response } = operation.getContext();
    if (response && response.status === 401) {
        response.errors = null;
        refreshToken();
    }
});

export const apolloClient = new ApolloClient({
    link: authMiddleware().concat(errorLink).concat(httpLink),
    cache: new InMemoryCache(),
    fetchOptions: {
        credentials: "include",
    },
});
