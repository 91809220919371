import { gql } from "@apollo/client";

export const LOGBOOK_COUNT = gql`
    query logbook($unitId: Int) {
        uklid: logbooks(unitId: $unitId, typ: "uklid") {
            paginationInfo {
                totalCount
            }
        }
        zahrada: logbooks(unitId: $unitId, typ: "zahrada") {
            paginationInfo {
                totalCount
            }
        }
    }
`;

export const HELPDESK_SEARCH = gql`
    query helpdesk(
        $search: String!
        $userId: Int
        $private: [Int]
        $privateAdmin: Int
        $unitId: Int!
    ) {
        name: helpdesks(
            nazev: $search
            private_list: $private
            unitId: $unitId
            order: { number: "desc" }
        ) {
            paginationInfo {
                totalCount
            }
            collection {
                number
                nazev
                done
                private
                vlozeno
                typ
                lastUpdate
                userData {
                    userData {
                        fname
                        surname
                    }
                }
                vyrizujeData {
                    fname
                    surname
                }
            }
        }
        number: helpdesks(
            number: $search
            private_list: $private
            unitId: $unitId
            order: { number: "desc" }
        ) {
            paginationInfo {
                totalCount
            }
            collection {
                number
                nazev
                done
                private
                vlozeno
                typ
                lastUpdate
                userData {
                    userData {
                        fname
                        surname
                    }
                }
                vyrizujeData {
                    fname
                    surname
                }
            }
        }
        privateName: helpdesks(
            nazev: $search
            private: $privateAdmin
            userId: $userId
            unitId: $unitId
            order: { number: "desc" }
        ) {
            paginationInfo {
                totalCount
            }
            collection {
                number
                nazev
                done
                private
                vlozeno
                typ
                lastUpdate
                userData {
                    userData {
                        fname
                        surname
                    }
                }
                vyrizujeData {
                    fname
                    surname
                }
            }
        }
        privateNumber: helpdesks(
            number: $search
            private: $privateAdmin
            userId: $userId
            unitId: $unitId
            order: { number: "desc" }
        ) {
            paginationInfo {
                totalCount
            }
            collection {
                number
                nazev
                done
                private
                vlozeno
                typ
                lastUpdate
                userData {
                    userData {
                        fname
                        surname
                    }
                }
                vyrizujeData {
                    fname
                    surname
                }
            }
        }
    }
`;

export const LOGBOOK_LIST = gql`
    query logbook($unitId: Int, $page: Int, $typ: String) {
        logbooks(
            unitId: $unitId
            page: $page
            typ: $typ
            order: { vlozeno: "desc" }
        ) {
            paginationInfo {
                totalCount
            }
            collection {
                number
                nazev
                vlozeno
                typ
                lastUpdate
                minutes
                userData {
                    userData {
                        fname
                        surname
                    }
                }
            }
        }
    }
`;

export const LOGBOOK_DETAIL = gql`
    query logbooks($number: String!, $unitId: Int!) {
        detail: logbooks(number: $number, unitId: $unitId) {
            collection {
                _id
                number
                nazev
                done
                private
                vlozeno
                typ
                text
                userId
                lastUpdate
                userData {
                    userData {
                        fname
                        surname
                    }
                }
                workers {
                    hours
                    minutes
                    userData {
                        fname
                        surname
                    }
                }
                jobs {
                    jobName
                    workName
                }
                images: files(type: "img") {
                    filename
                    type
                    name
                    ext
                    hash
                }
                documents: files(
                    type_list: [
                        ""
                        "docx"
                        "pdf"
                        "xlsx"
                        "doc"
                        "zip"
                        "ods"
                        "xls"
                        "mov"
                        "msg"
                        "pptx"
                        "ppt"
                    ]
                ) {
                    filename
                    type
                    name
                    ext
                    hash
                }
            }
        }
    }
`;
