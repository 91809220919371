import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { loggedUrl, CONFIG_APP_API_ROUTE } from "../client";
import Logo from "../images/logo.svg";
import { AuthContext } from "./context/context";
import { PassPin } from "./user/PassPin";
import { CURRENT_USER } from "../query/user";
import { useLazyQuery } from "@apollo/client";

export default function Login(props) {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [resMessage, setResponse] = useState();
    const [userData, setUserData] = useState();
    const appData = props.appData;

    let module = "units";
    if (appData?.name === "logBook") {
        module = "logbook";
    }

    const [authContext, dispatch] = useContext(AuthContext);
    const user = authContext?.userData;

    const [getUserData] = useLazyQuery(CURRENT_USER, {
        onCompleted: (data) => {
            authContext.login(data.currentUser);
            return data;
        },
    });

    const onSubmit = async (data) => {
        dispatch({ type: "SET_LOADING" });
        try {
            const res = await axios.post(CONFIG_APP_API_ROUTE + "login_check", {
                username: data.login,
                password: data.pass,
            });
            if (res.data) {
                localStorage.setItem(
                    "token",
                    JSON.stringify({
                        ...res.data,
                        expiration: new Date(
                            new Date().getTime() + 60 * 60 * 1000
                        ),
                    })
                );
                await getUserData();
                dispatch({ type: "CLEAR_LOADING" });
                // let res2 = await axios.get(
                //     loggedUrl +
                //         "units/user_unit/" +
                //         response?.data?.currentUser?.userData?._id +
                //         "/",
                //     {
                //         headers: {
                //             "Content-Type": "application/json",
                //             Authorization: `Bearer ` + res.data.token,
                //         },
                //     }
                // );
                // const length = res2.data.units.length;
                // if (length === 1) {
                //     await setBuilding(res2.data.units[0].units_alias);
                // }
            }
        } catch (error) {
            setResponse(error?.response?.data?.message);
            setTimeout(() => {
                setResponse(false);
            }, 10000);
            dispatch({ type: "SET_LOADING" });
        }
    };

    function setPass() {
        dispatch({
            type: "SET_MODAL",
            payload: {
                component: PassPin,
                params: {
                    userData: userData,
                    setUserData: setUserData,
                    updateAction: props.updateAction,
                },
            },
        });
    }

    function setBuildings(data) {
        dispatch({
            type: "SET_BUILDINGS",
            payload: {
                buildings: data.buildings,
                allBuildings: data.allBuildings,
            },
        });
    }

    function setBuilding(data) {
        dispatch({
            type: "SET_BUILDING",
            payload: {
                building: data,
            },
        });
    }

    return (
        <>
            <div
                className={
                    "  bg-gradient-to-br from-gray-400  to-gray-700  flex-col justify-center   h-screen"
                }
            >
                <div className="py-16 bg-login bg-no-repeat bg-cover bg-left-bottom bg-opacity-20 h-full">
                    <div className="flex w-full lg:items-center lg:h-full">
                        <div className="w-full  overflow-hidden mx-auto max-w-sm lg:max-w-1xl lg:-mt-48">
                            <div className="w-full  bg-white bg-opacity-80 rounded-lg  ">
                                {appData?.title && (
                                    <div className="font-light text-3xl text-gray-200 text-center bg-gray-600 bg-opacity-90 border-white border-opacity-50 border rounded-t-lg py-4 ">
                                        {appData.title}
                                    </div>
                                )}
                                <div className="w-full p-8">
                                    <h2 className="text-2xl font-semibold  text-center py-5">
                                        <img
                                            src={Logo}
                                            alt="Stephanos Facility"
                                            className="h-14 mx-auto"
                                        />
                                    </h2>

                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <>
                                            <div className="mt-4">
                                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                                    E-mail
                                                </label>
                                                <input
                                                    autoFocus
                                                    type="text"
                                                    {...register("login", {
                                                        required: true,
                                                    })}
                                                    onChange={(e) =>
                                                        setUserData({
                                                            email: e.target
                                                                .value,
                                                        })
                                                    }
                                                    className=" text-gray-700 focus:outline-none focus:shadow-outline focus:ring-green-500 focus:border-green-500 border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                                                />
                                                {errors.login && (
                                                    <div className="rounded-md bg-red-50 mt-1 p-2">
                                                        <svg
                                                            className="h-5 w-5 text-red-400 inline mr-3"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                        <span className="text-sm text-red-700">
                                                            Vyplňte přihlašovací
                                                            jméno
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mt-4">
                                                <div className="flex justify-between">
                                                    <label className="block text-gray-700 text-sm font-bold mb-2">
                                                        Heslo
                                                    </label>
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            setPass()
                                                        }
                                                        tabIndex="-1"
                                                        className=" text-sm text-gray-500"
                                                    >
                                                        Neznáte heslo?
                                                    </button>
                                                </div>
                                                <input
                                                    type="password"
                                                    {...register("pass", {
                                                        required: true,
                                                    })}
                                                    className="  text-gray-700 focus:outline-none focus:shadow-outline focus:ring-green-500 focus:border-green-500 border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                                                />
                                                {errors.pass && (
                                                    <div className="rounded-md bg-red-50 mt-1 p-2">
                                                        <svg
                                                            className="h-5 w-5 text-red-400 inline mr-3"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                        <span className="text-sm text-red-700">
                                                            Vyplňte heslo
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </>

                                        {resMessage && (
                                            <div className="rounded-md bg-red-50 mt-2 p-2">
                                                <svg
                                                    className="h-5 w-5 text-red-400 inline mr-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                                <span className="text-sm text-red-700">
                                                    {resMessage}
                                                </span>
                                            </div>
                                        )}
                                        <div className="mt-8">
                                            {loading ? (
                                                <button
                                                    disabled
                                                    className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600"
                                                >
                                                    <svg
                                                        className="animate-spin -ml-1 mr-3 h-5 w-5 inline"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        ></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        ></path>
                                                    </svg>
                                                    <span>Přihlašování...</span>
                                                </button>
                                            ) : (
                                                <button className="bg-green-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-green-800">
                                                    Přihlásit
                                                </button>
                                            )}
                                        </div>
                                    </form>

                                    {appData?.signUp ? (
                                        <div className="mt-7 text-center text-gray-600 text-sm ">
                                            <span>Nemáte přístup?</span>{" "}
                                            <NavLink
                                                to="/registrace"
                                                className="underline hover:no-underline"
                                            >
                                                Registrujte se
                                            </NavLink>
                                        </div>
                                    ) : (
                                        <div className="my-6"></div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
