import { ExclamationCircleIcon } from "@heroicons/react/solid";
import React, { useState, useContext, useEffect } from "react";
import Logo from "../images/logo.svg";
import BuildingList from "./buildings";

import { AuthContext } from "./context/context";
import { Loading } from "./layout/Loading";

export default function Select(props) {
    const [authContext] = useContext(AuthContext);
    const buildings = authContext.buildings;

    return (
        <>
            <div
                className={
                    "  bg-gradient-to-br from-gray-400  to-gray-700  flex-col justify-center   h-screen"
                }
            >
                <div className=" bg-login bg-no-repeat bg-cover bg-left-bottom bg-opacity-20 h-screen  max-h-screen  overflow-auto py-16">
                    <div className="flex w-full lg:items-center">
                        <div className="flex bg-white bg-opacity-80 rounded-lg w-full shadow-2xl mx-auto max-w-sm lg:max-w-3xl h-full  relative ">
                            <button
                                className="text-gray-200 flex rounded-md px-10 py-2 text-sm leading-5 text-left   hover:bg-black hover:bg-opacity-10 mx-auto absolute top-0 right-0 -mt-10  "
                                onClick={authContext.logout}
                            >
                                <svg
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 511.996 511.996"
                                    className="h-5 w-5 mr-2 inline"
                                    fill="currentColor"
                                    stroke="none"
                                >
                                    <path
                                        d="M349.85,62.196c-10.797-4.717-23.373,0.212-28.09,11.009c-4.717,10.797,0.212,23.373,11.009,28.09
                                                  c69.412,30.324,115.228,98.977,115.228,176.035c0,106.034-85.972,192-192,192c-106.042,0-192-85.958-192-192
                                                  c0-77.041,45.8-145.694,115.192-176.038c10.795-4.72,15.72-17.298,10.999-28.093c-4.72-10.795-17.298-15.72-28.093-10.999
                                                  C77.306,99.275,21.331,183.181,21.331,277.329c0,129.606,105.061,234.667,234.667,234.667
                                                  c129.592,0,234.667-105.068,234.667-234.667C490.665,183.159,434.667,99.249,349.85,62.196z"
                                    />
                                    <path
                                        d="M255.989,234.667c11.782,0,21.333-9.551,21.333-21.333v-192C277.323,9.551,267.771,0,255.989,0
                                                  c-11.782,0-21.333,9.551-21.333,21.333v192C234.656,225.115,244.207,234.667,255.989,234.667z"
                                    />
                                </svg>
                                Odhlásit
                            </button>

                            <div className="w-full  h-full ">
                                <div className="flex justify-between px-8 my-4 ">
                                    <h2 className="text-xl text-gray-600  ">
                                        Zvolte objekt
                                    </h2>
                                    <img
                                        src={Logo}
                                        alt="Stephanos Facility"
                                        className="h-8"
                                    />
                                </div>

                                <div className="bg-white rounded-md relative  ">
                                    <BuildingList {...props} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
