import { useContext } from "react";
import { AuthContext } from "../context/context";
import { Info } from "./Info";

export const FileList = ({ files, setModalContent, setModalIsOpen }) => {
    const [authContext, dispatch] = useContext(AuthContext);

    function infoModal(img) {
        dispatch({
            type: "SET_MODAL",
            payload: {
                component: Info,
                params: {
                    title: "",
                    content: img,
                },
            },
        });
    }

    return (
        <>
            <ul className="  my-3">
                {files.map((file, index) => {
                    return (
                        <li
                            key={index}
                            className="col-span-1  relative inline-block border-gray-300 border mr-2 cursor-pointer hover:bg-gray-200"
                            onClick={() =>
                                infoModal(
                                    <img src={file.filename} alt={file.name} />
                                )
                            }
                        >
                            <div className="flex-1 min-w-0   h-24 w-24 m-2  overflow-hidden ">
                                {file.type === "img" ? (
                                    <img
                                        src={file.filename}
                                        alt={file.name}
                                        className="object-cover h-24 w-full"
                                    />
                                ) : (
                                    <p className="text-sm font-medium text-gray-900 truncate">
                                        {file.name}.{file.ext}
                                    </p>
                                )}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </>
    );
};
