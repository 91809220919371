import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import Layout from "./components/layout";
import NewItem from "./components/helpdesk/newItem";
import ListItems from "./components/helpdesk/list";
import HelpdeskDetail from "./components/helpdesk/detail";
import Sent from "./components/sent";
import MyList from "./components/helpdesk/myList";
import { New } from "./components/logbook/New";
import Login from "./components/login";
import Select from "./components/select";
import { UserSettings } from "./components/userSettings/UserSettings";
import LogbookList from "./components/logbook/logbookList";
import LogbookDetail from "./components/logbook/logbookDetail";
import { Home } from "./components/facility/Home";
import { SignUp } from "./components/facility/SignUp";
import { SignUpSent } from "./components/facility/SignUpSent";
import { Dashboard } from "./components/dashboard/Dashboard";
import { Contacts } from "./components/facility/Contacts";
import { Revision } from "./components/facility/Revision";
import { Economics } from "./components/facility/Economics";
import { Documents } from "./components/facility/Documents";
import { Services } from "./components/facility/Services";
import { Vote } from "./components/facility/Vote";
import { useContext } from "react";
import { AuthContext } from "./components/context/context";
import { PassPin } from "./components/user/PassPin";

const ProtectedRoute = ({ redir, redirectPath = "/login" }) => {
    if (!redir) {
        return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />;
};

export const MainRoutes = () => {
    const [state] = useContext(AuthContext);

    return (
        <Routes>
            <Route
                path="/login"
                element={
                    !state.isLoggedIn ? (
                        <Layout hideLayout={true} component={Login} />
                    ) : (
                        <Navigate to="/vyber-objektu" replace />
                    )
                }
            />
            <Route
                path="/heslo"
                element={
                    !state.isLoggedIn ? (
                        <Layout hideLayout={false} component={PassPin} />
                    ) : (
                        <Navigate to="/vyber-objektu" replace />
                    )
                }
            />
            <Route path="/registrace" element={<Layout component={SignUp} />} />
            <Route
                path="/registrace-odeslana"
                element={<Layout component={SignUpSent} />}
            />
            <Route
                element={
                    <ProtectedRoute
                        redir={state.isLoggedIn}
                        redirectPath={"/login"}
                    />
                }
            >
                <Route
                    path="/vyber-objektu"
                    element={
                        !state.building?.unit ? (
                            <Layout hideLayout={true} component={Select} />
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />

                <Route
                    element={
                        <ProtectedRoute
                            redir={state.building}
                            redirectPath={"/vyber-objektu"}
                        />
                    }
                >
                    <Route path="/" element={<Layout component={Home} />} />
                    <Route
                        path="/formular-odeslan"
                        element={<Layout component={Sent} />}
                    />
                    <Route
                        path="/objekt/:buildingParam"
                        element={<Layout component={NewItem} />}
                    />
                    <Route
                        path="/nastaveni"
                        element={<Layout component={UserSettings} />}
                    />
                    <Route
                        path="/nastenka/*"
                        element={<Layout component={Dashboard} />}
                    >
                        <Route
                            path=":typ"
                            element={<Layout component={Dashboard} />}
                        />
                    </Route>
                    <Route
                        path="/technicke"
                        element={<Layout component={Revision} />}
                    />
                    <Route
                        path="/ekonomicke"
                        element={<Layout component={Economics} />}
                    />
                    <Route
                        path="/hlasovani"
                        element={<Layout component={Vote} />}
                    />
                    <Route
                        path="/sluzby"
                        element={<Layout component={Services} />}
                    />
                    <Route
                        path="/dokumenty"
                        element={<Layout component={Documents} />}
                    />
                    <Route
                        path="/kontakty"
                        element={<Layout component={Contacts} />}
                    />
                    <Route path="/helpdesk" element={<Outlet />}>
                        <Route
                            index
                            element={<Layout component={ListItems} />}
                        />
                        <Route
                            path=":typ"
                            element={<Layout component={ListItems} />}
                        >
                            <Route
                                path=":page"
                                element={<Layout component={ListItems} />}
                            />
                        </Route>
                        <Route path="denik">
                            <Route
                                path=":logbookTyp"
                                element={<Layout component={LogbookList} />}
                            >
                                <Route
                                    path=":page"
                                    element={<Layout component={LogbookList} />}
                                />
                            </Route>
                            <Route path="zaznam">
                                <Route
                                    path=":zaznam"
                                    element={
                                        <Layout component={LogbookDetail} />
                                    }
                                />
                            </Route>
                        </Route>
                    </Route>

                    <Route
                        path="/helpdesk/moje"
                        element={<Layout component={MyList} />}
                    />
                    <Route
                        path="helpdesk/pozadavek/:pozadavek"
                        element={<Layout component={HelpdeskDetail} />}
                    />
                    <Route
                        path="/helpdesk/novy"
                        element={<Layout component={NewItem} />}
                    />
                </Route>
            </Route>
        </Routes>
    );
};

export const MyRoutes = () => {
    const [state] = useContext(AuthContext);
    const location = useLocation();
    const appData = state.appData;

    if (appData.name === "logBook" && location.pathname === "/") {
        if (!state.isLoggedIn) {
            return <Navigate to={"/login"} replace />;
        } else {
            return <Navigate to={"/denik/novy"} replace />;
        }
    }

    return (
        <Routes>
            <Route
                path="/login"
                element={
                    state.isLoggedIn ? (
                        <Navigate to="/vyber-objektu" replace />
                    ) : (
                        <Layout hideLayout={true} component={Login} />
                    )
                }
            />

            <Route
                path="/vyber-objektu"
                element={
                    state.isLoggedIn && !state.building?.unit ? (
                        <Layout hideLayout={true} component={Select} />
                    ) : state.isLoggedIn ? (
                        <Navigate to="/" replace />
                    ) : (
                        <Navigate to="/login" replace />
                    )
                }
            />

            <Route path="/" element={<Layout component={NewItem} />} />

            <Route
                path="/denik"
                element={
                    !state.isLoggedIn ? (
                        <Navigate to="/login" replace />
                    ) : state.building ? (
                        <Outlet />
                    ) : (
                        <Navigate to={"/vyber-objektu"} replace />
                    )
                }
            >
                <Route index element={<Layout component={LogbookList} />} />
                <Route
                    path=":logbookTyp"
                    element={<Layout component={LogbookList} />}
                >
                    <Route
                        path=":page"
                        element={<Layout component={LogbookList} />}
                    />
                </Route>
                <Route
                    path="novy"
                    element={
                        state.building ? (
                            <Layout component={New} />
                        ) : (
                            <Navigate to={"/vyber-objektu"} replace />
                        )
                    }
                />
                <Route path="zaznam">
                    <Route
                        path=":zaznam"
                        element={
                            state.isLoggedIn ? (
                                <Layout component={LogbookDetail} />
                            ) : (
                                <Navigate to={"/"} replace />
                            )
                        }
                    />
                </Route>
            </Route>
            <Route
                path="/formular-odeslan"
                element={<Layout component={Sent} />}
            />
            <Route
                path="/objekt/:buildingParam"
                element={
                    state.isLoggedIn ? (
                        <Layout component={NewItem} />
                    ) : (
                        <Navigate to={"/"} replace />
                    )
                }
            />

            <Route
                path="/pozadavky"
                render={(props) =>
                    state.isLoggedIn ? (
                        <Layout {...props} component={ListItems} />
                    ) : (
                        <Navigate to={"/"} replace />
                    )
                }
            />
            <Route
                path="/helpdesk"
                element={
                    state.isLoggedIn ? (
                        !state.building?.unit ? (
                            <Navigate to="/vyber-objektu" replace />
                        ) : (
                            <Outlet />
                        )
                    ) : (
                        <Navigate to={"/"} replace />
                    )
                }
            >
                <Route index element={<Layout component={ListItems} />} />
                <Route path=":typ" element={<Layout component={ListItems} />}>
                    <Route
                        path=":page"
                        element={<Layout component={ListItems} />}
                    />
                </Route>
                <Route
                    path="moje"
                    element={
                        state.isLoggedIn ? (
                            <Layout component={MyList} />
                        ) : (
                            <Navigate to={"/"} replace />
                        )
                    }
                />
                <Route path="pozadavek">
                    <Route
                        path=":pozadavek"
                        element={
                            state.isLoggedIn ? (
                                <Layout component={HelpdeskDetail} />
                            ) : (
                                <Navigate to={"/"} replace />
                            )
                        }
                    />
                </Route>
                <Route
                    path="novy"
                    element={
                        state.isLoggedIn ? (
                            <Layout component={NewItem} />
                        ) : (
                            <Navigate to={"/"} replace />
                        )
                    }
                />
                <Route path="denik">
                    <Route
                        path=":logbookTyp"
                        element={<Layout component={LogbookList} />}
                    >
                        <Route
                            path=":page"
                            element={<Layout component={LogbookList} />}
                        />
                    </Route>
                </Route>
            </Route>

            <Route
                path="/nastaveni"
                element={
                    state.isLoggedIn ? (
                        !state.building ? (
                            <Navigate to="/vyber-objektu" replace />
                        ) : (
                            <Layout component={UserSettings} />
                        )
                    ) : (
                        <Navigate to="/login" replace />
                    )
                }
            />
        </Routes>
    );
};
