import { gql } from "@apollo/client";

export const HELPDESK_COUNT = gql`
    query helpdesk(
        $unitId: Int
        $vyrizuje: Int
        $userId: Int
        $private: [Int]
    ) {
        active: helpdesks(unitId: $unitId, done: 0, private_list: $private) {
            paginationInfo {
                totalCount
            }
        }
        done: helpdesks(unitId: $unitId, done: 1, private_list: $private) {
            paginationInfo {
                totalCount
            }
        }
        postponed: helpdesks(unitId: $unitId, done: 2, private_list: $private) {
            paginationInfo {
                totalCount
            }
        }
        my: helpdesks(vyrizuje: $vyrizuje, done: 0) {
            paginationInfo {
                totalCount
            }
        }
        private: helpdesks(private: 1, userId: $userId, unitId: $unitId) {
            paginationInfo {
                totalCount
            }
        }
    }
`;

export const HELPDESK_SEARCH = gql`
    query helpdesk(
        $search: String!
        $userId: Int
        $private: [Int]
        $privateAdmin: Int
        $unitId: Int!
    ) {
        name: helpdesks(
            nazev: $search
            private_list: $private
            unitId: $unitId
            order: { number: "desc" }
        ) {
            paginationInfo {
                totalCount
            }
            collection {
                number
                nazev
                done
                private
                vlozeno
                typ
                lastUpdate
                unitData {
                    name
                }
                userData {
                    userData {
                        fname
                        surname
                    }
                }
                vyrizujeData {
                    fname
                    surname
                }
            }
        }
        number: helpdesks(
            number: $search
            private_list: $private
            unitId: $unitId
            order: { number: "desc" }
        ) {
            paginationInfo {
                totalCount
            }
            collection {
                number
                nazev
                done
                private
                vlozeno
                typ
                lastUpdate
                userData {
                    userData {
                        fname
                        surname
                    }
                }
                vyrizujeData {
                    fname
                    surname
                }
            }
        }
        privateName: helpdesks(
            nazev: $search
            private: $privateAdmin
            userId: $userId
            unitId: $unitId
            order: { number: "desc" }
        ) {
            paginationInfo {
                totalCount
            }
            collection {
                number
                nazev
                done
                private
                vlozeno
                typ
                lastUpdate
                userData {
                    userData {
                        fname
                        surname
                    }
                }
                vyrizujeData {
                    fname
                    surname
                }
            }
        }
        privateNumber: helpdesks(
            number: $search
            private: $privateAdmin
            userId: $userId
            unitId: $unitId
            order: { number: "desc" }
        ) {
            paginationInfo {
                totalCount
            }
            collection {
                number
                nazev
                done
                private
                vlozeno
                typ
                lastUpdate
                userData {
                    userData {
                        fname
                        surname
                    }
                }
                vyrizujeData {
                    fname
                    surname
                }
            }
        }
    }
`;

export const HELPDESK_LIST = gql`
    query helpdesk(
        $unitId: Int
        $page: Int
        $private: [Int]
        $userId: Int
        $done: Int
        $vyrizuje: Int
    ) {
        helpdesks(
            unitId: $unitId
            page: $page
            done: $done
            private_list: $private
            userId: $userId
            vyrizuje: $vyrizuje
            order: { number: "desc" }
        ) {
            paginationInfo {
                totalCount
            }
            collection {
                number
                nazev
                done
                private
                vlozeno
                typ
                lastUpdate
                unitData {
                    name
                }
                userData {
                    userData {
                        fname
                        surname
                    }
                }
                vyrizujeData {
                    fname
                    surname
                }
            }
        }
    }
`;

export const HELPDESK_DETAIL = gql`
    query helpdesks($number: String!, $unitId: Int) {
        detail: helpdesks(number: $number, unitId: $unitId) {
            collection {
                _id
                number
                nazev
                done
                private
                vlozeno
                typ
                text
                userId
                lastUpdate
                unitData {
                    name
                }
                userData {
                    userData {
                        fname
                        surname
                        img
                    }
                }
                vyrizujeData {
                    fname
                    surname
                    img
                }
                images: files(type: "img") {
                    filename
                    type
                    name
                    ext
                    hash
                }
                documents: files(
                    type_list: [
                        ""
                        "docx"
                        "pdf"
                        "xlsx"
                        "doc"
                        "zip"
                        "ods"
                        "xls"
                        "mov"
                        "msg"
                        "pptx"
                        "ppt"
                    ]
                ) {
                    filename
                    type
                    name
                    ext
                    hash
                }
                commentsData {
                    userData {
                        fname
                        surname
                        img
                    }
                    message
                    postTime
                    images: files(type: "img") {
                        filename
                        type
                        name
                        ext
                        hash
                    }
                    documents: files(
                        type_list: [
                            ""
                            "docx"
                            "pdf"
                            "xlsx"
                            "doc"
                            "zip"
                            "ods"
                            "xls"
                            "mov"
                            "msg"
                            "pptx"
                            "ppt"
                        ]
                    ) {
                        filename
                        type
                        name
                        ext
                        hash
                    }
                }
            }
        }
    }
`;
