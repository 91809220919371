import { refreshToken } from "./refreshToken";

export async function tokenExpiration(logout) {
    const token = JSON.parse(localStorage.getItem("token"));
    const expiration = token?.expiration;
    if (token) {
        const remainingTime =
            new Date(expiration).getTime() - new Date().getTime();
        setTimeout(function () {
            refreshToken(logout);
        }, remainingTime);
    } else {
        logout();
    }
}
