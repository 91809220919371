import { XIcon } from "@heroicons/react/solid";
import React from "react";
import { Error } from "../../form/form";
import { UserSearch } from "./UserSearch";

export const UserSelect = ({
  workers,
  setWorkers,
  register,
  setValue,
  getValues,
  errors,
}) => {
  function submit(event, worker) {
    let newWorker = {
      ...worker,
      hours: getValues("hours" + worker.id),
      minutes: getValues("minutes" + worker.id),
    };
    setWorkers(newWorker);
  }

  function total() {
    let hours = 0;
    let minutes = 0;
    workers.forEach((worker) => {
      if (parseInt(worker?.hours)) {
        hours = hours + parseInt(worker?.hours);
      }
      if (parseInt(worker?.minutes)) {
        minutes = minutes + parseInt(worker?.minutes);
      }
    });

    return hours + " h " + minutes + " min";
  }

  return (
    <>
      <label
        htmlFor="name"
        className="block text-sm font-medium text-gray-700 mb-1"
      >
        Pracovníci
      </label>
      {workers.length > 0 && (
        <>
          <div className="mb-4 grid gap-4">
            {workers.map((worker, workerIdx) => (
              <div
                key={workerIdx}
                className="grid grid-cols-2 border rounded-md border-gray-300 p-3 gap-x-4 relative bg-gray-100"
              >
                <div className="col-span-2 flex justify-end w-full absolute right-2 top-2">
                  <button
                    type="button"
                    onClick={() => setWorkers(worker, true)}
                  >
                    <XIcon className="w-4 h-4" />
                  </button>
                </div>
                <div className="flex   pb-3 font-medium">{worker.name}</div>
                <div className="col-span-1 sm:col-span-1">
                  <label
                    htmlFor={"time" + worker.id}
                    className="block text-sm  text-gray-700"
                  >
                    Strávený čas
                  </label>
                  <div className="mt-1 flex items-center space-x-2">
                    <input
                      type="number"
                      placeholder="h"
                      id={"hours" + worker.id}
                      {...register("hours" + worker.id)}
                      onKeyUp={(e) => submit(e, worker)}
                      className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-20 sm:text-sm border-gray-300 rounded-md"
                    />{" "}
                    <input
                      type="number"
                      placeholder="min"
                      id={"minutes" + worker.id}
                      {...register("minutes" + worker.id, { required: true })}
                      onKeyUp={(e) => submit(e, worker)}
                      className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-20 sm:text-sm border-gray-300 rounded-md"
                    />{" "}
                  </div>
                  {errors["hours" + worker.id] && <Error text="Vyplňte čas" />}
                  {errors["minutes" + worker.id] && (
                    <Error text="Vyplňte čas" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <div className="mb-3 text-center text-sm">
        <strong>{workers.length}</strong>{" "}
        {workers.length === 1
          ? "osoba"
          : workers.length >= 2 && workers.length < 5
          ? "osoby"
          : "osob"}
        , celkový čas: <strong>{total()}</strong>
      </div>
      <div className="lg:grid lg:grid-cols-2">
        <UserSearch
          setWorkers={setWorkers}
          workers={workers}
          register={register}
          setValue={setValue}
        />
      </div>
    </>
  );
};
