import React, { useState, useMemo, useContext, useEffect } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import LogbookLayout from "./logbook-layout";
import moment from "moment";
import "moment/locale/cs";
import { SubTitle } from "../layout/Title";
import { AuthContext } from "../context/context";
import { useQuery } from "@apollo/client";
import { HELPDESK_LIST } from "../../query/helpdesk";
import { LogbookItem } from "./logbookItem";
import { Pagination } from "../layout/pagination";
import { Loading } from "../layout/Loading";
import { LOGBOOK_LIST } from "../../query/logbook";

export default function LogbookList(props) {
    let { logbookTyp: typ, page } = useParams();
    let navigate = useNavigate();
    const building = props.building;

    const [authContext, dispatch] = useContext(AuthContext);
    const isAdmin = authContext.admin;
    const appData = authContext.appData;

    useEffect(() => {
        dispatch({ type: "SET_TITLE", payload: { title: "Pracovní deník" } });
    }, []);

    if (!page) {
        page = 1;
    }
    if (!typ) {
        typ = "uklid";
    }

    const pagination = 30;
    const { loading, data } = useQuery(LOGBOOK_LIST, {
        variables: {
            unitId: parseInt(building?.unit?.units_id),
            //vyrizuje: authContext?.userData?._id,
            typ: typ,
            page: parseInt(page),
            //order: { vlozeno: "desc" },
        },
        fetchPolicy: "cache-and-network",
    });

    const logbookList = data?.logbooks?.collection || [];
    const totalCount = data?.logbooks?.paginationInfo?.totalCount;

    useEffect(() => {
        if (typ === "uklid") {
            dispatch({
                type: "SET_TITLE",
                payload: { title: "Úklid" },
            });
        } else if (typ === "zahrada") {
            dispatch({
                type: "SET_TITLE",
                payload: { title: "Zahrada" },
            });
        } else {
            dispatch({
                type: "SET_TITLE",
                payload: { title: "Pracovní deník" },
            });
        }
        dispatch({
            type: "NAVIGATION_ACTIVE",
            payload: {
                active: typ,
            },
        });
        // eslint-disable-next-line
    }, [typ]);

    const handlePageClick = (e) => {
        let url = "/denik";
        const selectedPage = e.selected + 1;
        if (typ) {
            url = url + "/" + typ;
        }

        if (selectedPage === 1) {
            navigate(url);
        } else {
            navigate(url + "/" + selectedPage);
        }
    };

    return (
        <>
            {loading && <Loading loading={loading} />}
            <LogbookLayout {...props}>
                <div className=" divide-y divide-gray-200">
                    <div className="mt-2 divide-y divide-gray-200">
                        {logbookList?.map((item, itemIdx) => (
                            <LogbookItem
                                key={itemIdx}
                                item={item}
                                appData={appData}
                            />
                        ))}
                    </div>
                </div>

                {totalCount > pagination && (
                    <Pagination
                        page={page}
                        totalCount={totalCount}
                        pagination={pagination}
                        handlePageClick={handlePageClick}
                    />
                )}
            </LogbookLayout>
        </>
    );
}
