import { PlusIcon } from "@heroicons/react/outline";
import { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../context/context";
import { TypeButton } from "./typeButton";

export const TopMenu = ({ module }) => {
    const [authContext] = useContext(AuthContext);
    const appData = authContext.appData;
    const location = useLocation();

    if (appData.name === "logBook" || appData.name === "helpDesk") {
        return location.pathname === "/helpdesk/novy" ||
            (location.pathname === "/" && appData.name === "helpDesk") ? (
            ""
        ) : (
            <>
                <NavLink
                    to={
                        "/" +
                        (module === "helpdesk" ? "helpdesk" : "denik") +
                        "/novy"
                    }
                    className="fixed bg-blue-600 rounded-full text-white  z-50 bottom-6 right-6 flex justify-center items-center shadow-md h-14 w-14"
                >
                    <PlusIcon className="w-6 h-6" />
                </NavLink>
            </>
        );
    }

    return (
        <>
            {module === "helpdesk" && (
                <NavLink
                    to={
                        "/" +
                        (module === "helpdesk" ? "helpdesk" : "denik") +
                        "/novy"
                    }
                    className="fixed bg-blue-600 rounded-full text-white  z-50 bottom-6 right-6 flex justify-center items-center shadow-md h-14 w-14"
                >
                    <PlusIcon className="w-6 h-6" />
                </NavLink>
            )}

            {location.pathname === "/helpdesk/novy" ||
            (location.pathname === "/" && appData.name === "helpDesk") ? (
                ""
            ) : (
                <>
                    {/* <NavLink
                            to={
                                "/" +
                                (module === "helpdesk" ? "helpdesk" : "denik") +
                                "/novy"
                            }
                            className="fixed bg-blue-600 rounded-full text-white  z-50 bottom-6 right-6 flex justify-center items-center shadow-md h-14 w-14"
                        >
                            <PlusIcon className="w-6 h-6" />
                        </NavLink> */}
                </>
            )}
            {authContext.isLoggedIn && (
                <div className="bg-gray-200  border-b border-gray-300 w-full overflow-x-auto no-scrollbar flex py-4  px-5 space-x-2  lg:space-x-4">
                    <TypeButton
                        to={"/helpdesk"}
                        title={"Správa & údržba"}
                        module={module}
                        main={true}
                    />
                    <TypeButton
                        to={"/helpdesk/denik/uklid"}
                        title={"Úklid"}
                        module={module}
                        main={true}
                        type={"uklid"}
                    />
                    <TypeButton
                        to={"/helpdesk/denik/zahrada"}
                        title={"Zahrada"}
                        module={module}
                        main={true}
                        type={"zahrada"}
                    />
                </div>
            )}
        </>
    );
};
