import React from "react";
export const Title = (props) => {
    const bold = props.bold;
    const title = props.title;

    return (
        <div className="w-full ">
            <h3 className="text-lg text-gray-500 md:text-2xl mx-auto border-b border-gray-200 pb-2">
                <span className="font-bold">{bold}</span> {title}
            </h3>
        </div>
    );
};

export const SubTitle = (props) => {
    const bold = props.bold;
    const title = props.title;

    return (
        <div className="w-full mt-8">
            <h3 className="text-lg font-medium text-green-600 mx-auto border-b border-green-400 pb-2">
                <span className="font-bold">{bold}</span> {title}
            </h3>
        </div>
    );
};
