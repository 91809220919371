import React, { useMemo, useState } from 'react';
import { axiosProtected } from '../../../client';
import { Empty } from '../../layout/Empty';
import { Loading } from '../../layout/Loading';
import Branch from './Branch';
import { format  } from 'date-fns'
import {  CalendarIcon, DotsHorizontalIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/outline';
import { Menu,Transition } from '@headlessui/react'; 

import { cs } from 'date-fns/locale';


const colors = {
	'pdf': 'text-red-600',
	'xlsx': 'text-green-600',
	'docx': 'text-blue-600'
}

export const File = ({file}) => {
	let color = colors[file.ext]
	
	const [loading, setLoading] = useState(false);

	function downloadFile() {
		setLoading(true);
		(axiosProtected()).post(
			'/files/download/',
			{
				hash: file.hash
			},
			{
				headers:
				{
					'Content-Disposition': "inline;",
				},
				responseType: 'arraybuffer',
			}
		).then((response) => { 
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', file.name + '.' + file.ext); //or any other extension
			document.body.appendChild(link);
			link.click();
		}).catch(console.log).finally(() => {
			setLoading(false);
		});
	}

	return (
		<>
			<div className="flex justify-between text-gray-600  hover:bg-gray-100 lg:px-2 cursor-pointer" onClick={() => downloadFile()} target="_blank" rel="noreferrer"> 
				<div className="relative py-2 block overflow-hidden truncate" > 
					<svg className={" absolute h-4 w-4 lg:h-5 lg:w-5 mr-1 lg:mr-2  mt-0.5 " + (color && color)}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 317.001 317.001"  >
						<path d="M270.825,70.55L212.17,3.66C210.13,1.334,207.187,0,204.093,0H55.941C49.076,0,43.51,5.566,43.51,12.431V304.57
						c0,6.866,5.566,12.431,12.431,12.431h205.118c6.866,0,12.432-5.566,12.432-12.432V77.633
						C273.491,75.027,272.544,72.51,270.825,70.55z M55.941,305.073V12.432H199.94v63.601c0,3.431,2.78,6.216,6.216,6.216h54.903
						l0.006,222.824H55.941z" fill="currentColor" />  
						<rect width="200" height="130" x="10" y="150" fill="currentColor" />
						<text x="110" y="200" textAnchor="middle" dominantBaseline="central" fontSize="6em" fill="#ffffff" >{file.ext}</text> 
					</svg>				 
					 
					<p className="truncate ml-5 lg:ml-7 text-xs lg:text-sm">{file.name}</p> 
				</div>
				<div className="text-xs text-gray-400 flex items-center w-36 pr-1 overflow-hidden  justify-end">
					{file.inserted && 
						<span className="truncate">{format(new Date(file.inserted), 'd. M. y')}</span>
					}
				</div>
			</div>
			<Loading loading={loading} />
		</>
	)
}

const OrderFiles = ({name,title,updateFolderSettings,folderSettings}) => {
	 
	let activeOrder
	if(folderSettings?.order === name) {
		activeOrder = folderSettings
	} 
 
	return(
		<>
		<div className={"flex items-center w-full justify-between hover:bg-gray-100 p-2 px-4 cursor-pointer " + (activeOrder ? " text-green-600 font-medium" : " text-gray-400") } 
			onClick={() => updateFolderSettings({
				order: name,
        		direction: activeOrder ? activeOrder?.direction==='desc' ? 'asc' : 'desc' : 'desc',
        		months: folderSettings.months,
			})}
		>

			<div>{title}</div>
			<div >
				{activeOrder?.direction==='desc' &&
					<SortDescendingIcon className="w-4 h-4" />
				}
				{activeOrder?.direction==='asc' &&
					<SortAscendingIcon className="w-4 h-4" />
				}
			</div>

		</div>
		</>
	)
}

const Files = ({ files, activeFolder, folders, map,activeBranch, setActiveBranch, parents, setActiveFolder, updateFolderSettings, folderSettings, setSearchResults }) => {
 
	const [fileList, setFileList] = useState([]);
	const [subFolders, setSubFolders] = useState([]);

	function setMonths() {
		updateFolderSettings({
			order: folderSettings.order,
			direction: folderSettings.direction,
			months: !folderSettings.months,
		})
	}
 
	useMemo(() => {     
	   if(files[activeFolder]) {
		  let sorted
		   
		  if(folderSettings) { 
			if(folderSettings.direction==='desc') { 
				if(folderSettings.order==='inserted') {
					sorted = files[activeFolder].sort(function(a,b){ 
						return new Date(b[folderSettings.order]) - new Date(a[folderSettings.order]);
					}); 
				} else { 
					sorted = files[activeFolder].sort((a,b) => (a[folderSettings.order] > b[folderSettings.order]) ? 1 : ((b[folderSettings.order] > a[folderSettings.order]) ? -1 : 0)) 
				}

			} else {
				if(folderSettings.order==='inserted') {
					sorted = files[activeFolder].sort(function(a,b){ 
						return new Date(a[folderSettings.order]) - new Date(b[folderSettings.order]);
					}); 
				} else { 
					sorted = files[activeFolder].sort((a,b) => (a[folderSettings.order] < b[folderSettings.order]) ? 1 : ((b[folderSettings.order] < a[folderSettings.order]) ? -1 : 0)) 
				}
				
			}
		  } else {
			sorted = files[activeFolder];	
		  }
		  setFileList(sorted);
	   } else {
		  setFileList([]);
	   }
	   if(folders && activeFolder) {
		   let children  
		   children = folders.find(x => x.id === activeFolder)
		   children ? setSubFolders(children.children) : setSubFolders([])
	   } else {
			setSubFolders([])
	   }
	}
		// eslint-disable-next-line
	, [folders, activeFolder, activeBranch, folderSettings, files]);


	const FileList = ({fileList}) => {
		let month
		let oldMonth
		let title

		return (
			fileList.map((file,itemIdx) => {
				
				month = format(new Date(file.inserted), 'LLLL y',{ locale: cs })
				if(oldMonth !== month) { 
					title = true					
				} else {
					title = false
				}
				oldMonth = month
				  return (
					  <div key={itemIdx}>
					  {title && folderSettings.order === 'inserted' && folderSettings.months && <>
					    <div className="relative text-xs p-2">
							<div className="absolute inset-0 flex items-center" aria-hidden="true">
								<div className="w-full border-t border-gray-100" />
							</div>
							<div className="relative flex justify-start">
								<span className="pr-2 bg-white  text-gray-500">{oldMonth}</span>
							</div>
						</div>
					  </>}
					  <File file={file}  />
					  </div>
				  )
			})

		)
	}
  
	return (
		<>
		 
		 	{(subFolders.length === 0 && fileList.length === 0 && activeFolder) ? (
				 <Empty />

			 ) : activeFolder && (
				<>
				<div className="flex lg:justify-between  lg:divide-x  py-2 text-xs " > 
					<div className=" w-full " > 
						<OrderFiles
							title={'Název'}
							name={'name'}
							updateFolderSettings={updateFolderSettings} 
                        	folderSettings={folderSettings}
						/>
					</div>
					<div className="w-60 lg:w-40 flex ">
						<OrderFiles
							title={'Datum'}
							name={'inserted'}
							updateFolderSettings={updateFolderSettings} 
                        	folderSettings={folderSettings}
						/>
						<div className="relative">
							<Menu>
							{({ open }) => (
								<>
							
							
								<Menu.Button className={"flex max-w-xs   text-gray-600    rounded-md  items-center text-sm focus:outline-none  p-2 hover:bg-gray-100 " 
								+ (open ? ' bg-gray-100 ' : ' ') } id="user-menu">
								<div className="flex items-center">
									<DotsHorizontalIcon className="w-5 h-5" />
									
								</div>
								</Menu.Button>
								
								
							
								<Transition className="origin-top-right z-40 absolute top-8 right-0 mt-2 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
								show={open}
								enter="transition ease-out duration-100 transform"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="transition ease-in duration-75 transform"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
								>
								<Menu.Items
								static
								className="divide-y divide-gray-100 outline-none"
								>
								
								

								<div className="py-1">
								<Menu.Item>
									{({ active }) => (
										<button onClick={() => setMonths()}
										className={`${
											active
											? "bg-gray-100 text-gray-900"
											: "text-gray-800"
										} flex w-full px-4 py-2 text-sm leading-5 text-left space-x-2 items-center`}
										
										>
										<CalendarIcon className="w-5 h-5 opacity-50" />
										{folderSettings.months ? <span>Zrušit seskupení</span>
										: <span>Seskupit po měsících</span>}
										
										</button>
									)}
									</Menu.Item>
	
								</div>
								</Menu.Items>
							</Transition>
							</>
							)}
							</Menu>
						</div>
					</div>
				</div>
				</>
			 )
			  }

			  {subFolders && 
				  <div>
					  {subFolders.map((item,itemIdx) => {
                          return <Branch key={itemIdx} branch={activeBranch} item={item} level={0} activeBranch={activeBranch} setActiveBranch={setActiveBranch} activeFolder={activeFolder} setActiveFolder={setActiveFolder} parents={parents} files={fileList} setSearchResults={setSearchResults} />
                      })}
				  </div>
			  }
			  {fileList &&
			  	<div >
				   <FileList fileList={fileList} />
				  </div>
			  }
 
			  
			  
		</>
	);
};

export default Files;