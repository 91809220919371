import { useState } from "react"
import { axiosProtected } from "../../client";

export const Notification = ({data}) => {
    const [isOn, setIsOn] = useState(data.value);

    async function changeNotifications(alias) { 
        setIsOn(!isOn)
        try {
            await (axiosProtected()).post('units/notify_update/' + alias + '/')
        } catch(err) {
            console.log(err);
        }
        
    }
 
    return (
        <>
            <div className="flex items-center sm:col-span-4 cursor-pointer my-2 p-2 rounded-md hover:bg-gray-100" onClick={() => changeNotifications(data.alias)}>
               
                <button  
                type="button" aria-pressed="false" aria-labelledby="toggleLabel" 
                className={(isOn ? 'bg-green-600' : 'bg-gray-200' ) + " relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "}
                >
                <span className="sr-only">{data.name}</span>
                <span aria-hidden="true" 
                className={(isOn ? 'translate-x-5' : 'translate-x-0' ) + " pointer-events-none  inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}>
                </span>
                </button>
                <span className="ml-3" id="toggleLabel">
                <span className={(isOn ? 'text-gray-900 font-medium' : 'text-gray-400' ) + " text-sm  "}>{data.name}</span>
                </span>
            </div>
        </>
    )
}