import { format } from "date-fns";
import React from "react";
import { NavLink } from "react-router-dom";

export const HelpdeskItem = ({ item, typ, unit }) => {
    const inserted = item.vlozeno && format(new Date(item.vlozeno), "d. M. y");
    const done =
        item.lastUpdate && format(new Date(item.lastUpdate), "d. M. y");

    return (
        <>
            <NavLink
                key={item.id}
                to={"/helpdesk/pozadavek/" + item.number}
                className="py-4 flex items-center justify-between hover:bg-gray-50 p-3 rounded-md "
            >
                <div className="grid grid-cols-3 lg:grid-cols-12 text-sm w-full gap-4 items-center">
                    <div className="">
                        <span
                            className={
                                "font-medium " +
                                (item.done === "0" && "text-green-600")
                            }
                        >
                            {item.number}
                        </span>
                    </div>
                    <div>
                        <span className="flex items-start w-full justify-items-start">
                            <span className="w-6 flex items-start">
                                {item.private === 1 ? (
                                    <svg
                                        className="w-4 mr-1 pt-1 inline text-red-700"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        title="viditelné pouze výboru"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                        />
                                    </svg>
                                ) : (
                                    ""
                                )}
                                {item.private === 2 ? (
                                    <svg
                                        className="w-4 mr-1 pt-1 inline text-blue-700"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        title="soukromý požadavek"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                        />
                                    </svg>
                                ) : (
                                    ""
                                )}
                            </span>

                            {item.typ}
                        </span>
                    </div>

                    <div className="col-span-2 ">
                        <span className="flex items-start w-full justify-center">
                            <svg
                                className="w-4 mr-1 pt-1 inline text-gray-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                />
                            </svg>
                            {inserted}
                        </span>
                    </div>
                    <div className="col-span-4 text-base lg:text-sm order-first lg:order-none">
                        <h2 className="  font-medium text-gray-900 truncate ">
                            {item.nazev}
                        </h2>
                    </div>
                    <div className="lg:col-span-2 text-center">
                        {item?.userData?.userData && (
                            <span>
                                {item?.userData?.userData?.fname +
                                    " " +
                                    item?.userData?.userData?.surname}
                            </span>
                        )}
                    </div>

                    <div className="col-span-2 justify-end ">
                        {unit ? (
                            <>{item?.unitData?.name}</>
                        ) : (
                            <>
                                {item.done === 1 ? (
                                    <>
                                        <span className="flex items-start w-full justify-end lg:justify-center">
                                            <svg
                                                className="w-4 mr-1 pt-1 inline text-gray-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                            {done}
                                        </span>
                                    </>
                                ) : (
                                    item.vyrizujeData && (
                                        <div className="bg-gray-100 px-1 py-1 rounded-md flex items-center">
                                            <svg
                                                className="w-4 mr-2 pt-1 inline"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                                />
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                />
                                            </svg>
                                            Vyřizuje{" "}
                                            {item.vyrizujeData?.fname +
                                                " " +
                                                item.vyrizujeData?.surname}
                                        </div>
                                    )
                                )}
                            </>
                        )}
                    </div>
                </div>
            </NavLink>
        </>
    );
};
