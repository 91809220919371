import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
    AiOutlinePushpin,
    AiOutlineFolder,
    AiOutlineCheckSquare,
    AiOutlineShopping,
    AiOutlineCalculator,
    AiOutlinePhone,
} from "react-icons/ai";
import { IoIosHelpBuoy } from "react-icons/io";
import { GoChecklist } from "react-icons/go";
import User from "./layout/user.js";
import ConfirmDialog from "./dialog/confirm.js";
import { Transition } from "@headlessui/react";
import { Modal } from "./layout/modal";
import { Logo } from "./layout/logo";
import BuildingList from "./buildings";
import { AuthContext } from "./context/context";
import { axiosProtected } from "../client";
import { HomeIcon, CogIcon, UserCircleIcon } from "@heroicons/react/solid";
import { Loading } from "./layout/Loading.js";
import { LayoutWrapper } from "./layout-wrapper";
import LoginModal from "./loginModal";
import { tokenExpiration } from "../services/tokenExpiration.js";
import { refreshToken } from "../services/refreshToken.js";
import { CURRENT_USER } from "../query/user.js";
import { useLazyQuery } from "@apollo/client";

const menuGroup = {
    default: [
        { name: "Nástěnka", href: "/nastenka", icon: AiOutlinePushpin },
        { name: "Dokumenty", href: "/dokumenty", icon: AiOutlineFolder },
        { name: "Hlasování", href: "/hlasovani", icon: AiOutlineCheckSquare },
        { name: "Objednání služeb", href: "/sluzby", icon: AiOutlineShopping },
        {
            name: "Ekonomické přehledy",
            href: "/ekonomicke",
            icon: AiOutlineCalculator,
        },
        { name: "Technické přehledy", href: "/technicke", icon: CogIcon },
        { name: "Kontakty", href: "/kontakty", icon: AiOutlinePhone },
        { name: "Helpdesk", href: "/helpdesk", icon: IoIosHelpBuoy },
    ],
    vybor: [
        { name: "Nástěnka", href: "/nastenka", icon: AiOutlinePushpin },
        { name: "Dokumenty", href: "/dokumenty", icon: "" },
        { name: "Hlasování", href: "/hlasovani", icon: "" },
        { name: "Objednání služeb", href: "/sluzby", icon: "" },
        { name: "Ekonomické přehledy", href: "/ekonomicke", icon: "" },
        { name: "Technické přehledy", href: "/technicke", icon: "" },
        { name: "Kontakty", href: "/kontakty", icon: "" },
        { name: "Helpdesk", href: "/helpdesk", icon: "" },
    ],
    logBook: [
        { name: "Helpdesk", href: "/helpdesk", icon: IoIosHelpBuoy },
        { name: "Pracovní deník", href: "/denik", icon: GoChecklist },
    ],
    helpDesk: [
        { name: "Helpdesk", href: "/helpdesk", icon: IoIosHelpBuoy },
        { name: "Pracovní deník", href: "/denik", icon: GoChecklist },
    ],
};

export const WhiteWrap = (props) => {
    return (
        <>
            <div className="  bg-white rounded-lg shadow ">
                <div className="divide-y divide-gray-200 lg:divide-y-0 lg:divide-x">
                    <div className="divide-y divide-gray-200 lg:col-span-9">
                        <div className="px-8 py-5">{props.children}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default function Layout({
    component: Component,
    setUser,
    helpdeskItems,
    setBuilding,
    buildings,
    setBuildings,
    response,
    hideLayout,
    logout,
    ...props
}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setDialogtitle] = useState(false);
    const [open, setOpen] = useState(false);
    //const [menu ] = useState(menuGroup.default);
    const [func, setFunc] = useState(false);
    const [loading, setLoading] = useState(false);

    const [authContext, dispatch] = useContext(AuthContext);

    const userData = authContext.userData;
    const user = authContext.isLoggedIn;
    const appData = authContext.appData;
    const building = authContext.building;
    const isLoggedIn = authContext.isLoggedIn;
    let menu = [];

    if (appData.name === "logBook") {
        isLoggedIn && (menu = menuGroup.logBook);
    } else if (appData.name === "helpDesk") {
        isLoggedIn && (menu = menuGroup.helpDesk);
    } else {
        user && (menu = menuGroup.default);
    }

    const navigate = useNavigate();

    function selectBuilding() {
        dispatch({
            type: "SET_MODAL",
            payload: {
                component: BuildingList,
                params: {
                    setBuilding: updateBuilding,
                    buildings: buildings,
                },
            },
        });
    }

    function dialog(title, func) {
        setDialogOpen(true);
        setDialogtitle(title);
        setFunc(() => func);
    }

    function onClose() {
        setDialogOpen(false);
    }

    function onConfirm(callback) {
        callback();
        setDialogOpen(false);
    }

    async function updateBuilding(building, data, redirect = true) {
        let url = "units/unit_data/";
        if (appData.name === "logBook") {
            url = "logbook/unit_data/";
        }
        dispatch({ type: "SET_LOADING" });
        if (building) {
            const response = await axiosProtected().get(url + building + "/");
            localStorage.setItem("building", JSON.stringify(response.data));
            dispatch({
                type: "SET_BUILDING",
                payload: {
                    building: response.data,
                },
            });
            dispatch({
                type: "SET_GROUP",
                payload: {
                    group: response.data.group,
                },
            });

            //setLoading(false);
        }
        if (data) {
            localStorage.setItem("building", JSON.stringify(data));
            dispatch({
                type: "SET_BUILDING",
                payload: {
                    building: data,
                },
            });
            dispatch({
                type: "SET_GROUP",
                payload: {
                    group: data.group,
                },
            });
        }
        dispatch({ type: "CLEAR_LOADING" });
        //navigate("/");
    }

    // useEffect(() => {
    //     function handleEscapeKey(event) {
    //         if (!dialogOpen) return;
    //         if (event.key === "Escape") {
    //             setDialogOpen(false);
    //         }
    //     }
    //     document.addEventListener("keyup", handleEscapeKey);
    //     return () => document.removeEventListener("keyup", handleEscapeKey);
    // }, [dialogOpen]);

    const [getUserData] = useLazyQuery(CURRENT_USER, {
        onCompleted: (data) => {
            authContext.login(data.currentUser);
            return data;
        },
    });

    useEffect(() => {
        authContext.isLoggedIn && !authContext.userData && getUserData();
    }, []);

    function setLogin() {
        setOpen(false);
        dispatch({
            type: "SET_MODAL",
            payload: {
                component: LoginModal,
                params: {
                    setBuildings: setBuildings,
                    setBuilding: updateBuilding,
                    building: building,
                },
            },
        });
    }

    return (
        <>
            {hideLayout ? (
                <Component
                    {...props}
                    setBuilding={updateBuilding}
                    setBuildings={setBuildings}
                    buildings={buildings}
                    building={building}
                    userData={userData}
                    title={title}
                    appData={appData}
                />
            ) : (
                <LayoutWrapper
                    {...props}
                    open={open}
                    building={building}
                    buildings={buildings}
                    selectBuilding={selectBuilding}
                    user={user}
                    logout={logout}
                    userData={userData}
                    setLogin={setLogin}
                    menu={menu}
                    appData={appData}
                    setOpen={setOpen}
                >
                    <Component
                        {...props}
                        setBuilding={updateBuilding}
                        setBuildings={setBuildings}
                        buildings={buildings}
                        building={building}
                        userData={userData}
                        dialog={dialog}
                        title={title}
                        appData={appData}
                    />
                </LayoutWrapper>
            )}
            <Transition
                show={open}
                className="fixed z-50 inset-0  transition-all  overflow-y-auto  h-full  backdrop-filter backdrop-blur-sm "
            >
                <Transition.Child
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                    enter="ease-out duration-50"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-50"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className="fixed top-0 bottom-0 inset-0 bg-black opacity-20 h-full z-10 "
                        onClick={() => setOpen(false)}
                    />
                </Transition.Child>
                <Transition.Child
                    className="  transform transition-all  "
                    enter="duration-150 ease-out "
                    enterFrom="opacity-0 scale-95 "
                    enterTo="opacity-100 scale-100 "
                    leave="duration-150 ease-in "
                    leaveFrom="opacity-100 scale-100 "
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top lg:hidden">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                            <div className="pt-3 pb-2">
                                <div className="flex items-center justify-between px-4">
                                    <div className="h-12">
                                        <Logo />
                                    </div>
                                    <div className="-mr-2">
                                        <button
                                            onClick={() => setOpen(false)}
                                            type="button"
                                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500"
                                        >
                                            <span className="sr-only">
                                                Close menu
                                            </span>

                                            <svg
                                                className="h-6 w-6"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-3 px-2 space-y-1">
                                    <NavLink
                                        to={"/"}
                                        onClick={() => setOpen(false)}
                                        className={({ isActive }) =>
                                            "flex space-x-2 rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 " +
                                            (isActive ? "bg-gray-100" : "")
                                        }
                                    >
                                        <HomeIcon className="h-5 w-5 opacity-50" />
                                        <span>Přehled</span>
                                    </NavLink>
                                    {menu.map((item, i) =>
                                        item.href ? (
                                            <NavLink
                                                key={i}
                                                to={item.href}
                                                onClick={() => setOpen(false)}
                                                className={({ isActive }) =>
                                                    "items-center rounded-md px-3 py-2 flex space-x-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 " +
                                                    (isActive
                                                        ? "bg-gray-100"
                                                        : "")
                                                }
                                            >
                                                {item.icon && (
                                                    <item.icon className="w-5 h-5 opacity-50" />
                                                )}
                                                <span>{item.name}</span>
                                            </NavLink>
                                        ) : (
                                            <span
                                                key={i}
                                                className="block rounded-md px-3 py-2 text-base text-gray-300 font-medium  "
                                            >
                                                {item.name}
                                            </span>
                                        )
                                    )}
                                </div>
                            </div>
                            <div className="pt-4 pb-2 ">
                                {user ? (
                                    <>
                                        <User
                                            logout={logout}
                                            user={userData}
                                            setOpen={setOpen}
                                            building={building}
                                        />
                                    </>
                                ) : (
                                    <button
                                        onClick={() => setLogin()}
                                        className="items-center rounded-md px-3 w-full mx-3 py-2 flex space-x-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 my-6"
                                        aria-current="false"
                                    >
                                        <UserCircleIcon className="h-5 w-5 opacity-50" />
                                        <span>Přihlásit</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </Transition.Child>
            </Transition>
            <Modal {...props} />

            <ConfirmDialog
                open={dialogOpen}
                onClose={onClose}
                onConfirm={onConfirm}
                func={func}
                title={title}
            />
            <Loading loading={authContext.loading} />
        </>
    );
}
