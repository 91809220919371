export const Reducer = (state, action) => {
    switch (action.type) {
        case "SET_MODAL":
            return {
                ...state,
                modal: {
                    component: action.payload.component,
                    params: action.payload.params,
                    open: true,
                },
            };
        case "SET_USERDATA":
            return {
                ...state,
                userData: action.payload.userData,
            };
        case "SET_LOADING":
            return {
                ...state,
                loading: true,
            };
        case "CLEAR_LOADING":
            return {
                ...state,
                loading: false,
            };
        case "SET_USER":
            return {
                ...state,
                isLoggedIn: true,
                token: action.payload.token,
                data: action.payload.data.data,
            };
        case "CLEAR_USER":
            return {
                ...state,
                isLoggedIn: false,
                userData: null,
                buildings: [],
                allBuildings: [],
                getBuildings: false,
                building: null,
            };
        case "SET_GROUP":
            return {
                ...state,
                userGroup: action.payload.group,
            };
        case "SET_ADMIN":
            return {
                ...state,
                admin: true,
            };
        case "SET_BUILDING":
            return {
                ...state,
                building: action.payload.building,
            };
        case "SET_BUILDINGS":
            return {
                ...state,
                buildings: action.payload.buildings,
                allBuildings: action.payload.allBuildings,
                getBuildings: true,
            };
        case "SET_USER":
            return {
                ...state,
                isLoggedIn: true,
                userData: action.data,
            };
        case "UPDATE_COUNT":
            return {
                ...state,
                helpdesk: {
                    ...state.helpdesk,
                    count: state.helpdesk.count + 1,
                },
            };
        case "NAVIGATION_ACTIVE":
            return {
                ...state,
                navigation: {
                    active: action.payload.active,
                },
            };
        case "SET_BREADCRUMB":
            return {
                ...state,
                breadCrumbPath: action.payload.breadCrumbPath,
            };
        case "SET_SEARCHSTRING":
            return {
                ...state,
                searchString: action.payload.searchString,
            };
        case "SET_TITLE":
            return {
                ...state,
                pageTitle: action.payload.title,
            };
        case "SET_TYPES":
            return {
                ...state,
                logbook: {
                    count: state.logbook.count,
                    types: action.payload.types,
                },
            };
        case "CLEAR_MODAL":
            return {
                ...state,
                modal: {
                    component: null,
                    params: null,
                    open: false,
                },
            };
        default:
            return state;
    }
};
