import { ExclamationCircleIcon } from "@heroicons/react/solid";
import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { axiosProtected } from "../client";
import { AuthContext } from "./context/context";
import { Loading } from "./layout/Loading";

export default function BuildingList(props) {
    const setBuilding = props.setBuilding;

    // const setBuildings = props.setBuildings;

    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => {};
    const [listBackup, setListBackup] = useState([]);
    const [displayAll, setDisplayAll] = useState(false);
    const [authContext, dispatch] = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const appData = authContext.appData;
    const building = authContext.building;
    const buildings = authContext.buildings;

    const [tempBuildings, setTempBuildings] = useState(buildings);

    let activeBuilding;
    if (building) {
        activeBuilding = building.unit;
    }

    const [active, setActive] = useState(activeBuilding);

    useEffect(() => {
        if (buildings && buildings.length > 0) {
            setTempBuildings(buildings);
            !activeBuilding && setActive(buildings[0]);
            // eslint-disable-next-line
            activeBuilding = buildings[0];
        }
    }, [buildings]);

    useEffect(() => {
        if (!displayAll) {
            setTempBuildings(authContext.buildings);
            setListBackup(authContext.buildings);
        } else {
            setTempBuildings(authContext.allBuildings);
            setListBackup(authContext.allBuildings);
        }
    }, [displayAll]);

    function useKeyPress(key, action) {
        useEffect(() => {
            function onKeyup(e) {
                if (e.key === key) action();
            }
            window.addEventListener("keyup", onKeyup);
            return () => window.removeEventListener("keyup", onKeyup);
            // eslint-disable-next-line
        }, [active, buildings]);
    }

    function search(e) {
        const val = e.target.value;
        let buildingList = listBackup;
        if (buildingList) {
            buildingList = buildingList.filter((building) => {
                let buildingName = building.units_name
                    .toUpperCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");
                return (
                    buildingName.indexOf(
                        val
                            .toUpperCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                    ) !== -1
                );
            });

            if (val) {
                setTempBuildings(buildingList);
                if (buildingList.length > 0) {
                    setActive(buildingList[0]);
                }
            } else {
                setTempBuildings(listBackup);
                setActive(activeBuilding);
            }
        }
    }

    async function confirmBuilding(building) {
        setActive(building);
        setBuilding(building.units_alias);
        dispatch({
            type: "CLEAR_MODAL",
        });
    }

    useKeyPress("Enter", () => {
        confirmBuilding(active);
    });

    useKeyPress("ArrowUp", () => {
        let activeKey;
        tempBuildings.forEach((building, index) => {
            if (active?.units_id === building.units_id) activeKey = index;
        });
        activeKey = activeKey - 1;
        if (activeKey < 0) {
            activeKey = 0;
        }
        setActive(tempBuildings[activeKey]);
    });

    useKeyPress("ArrowDown", () => {
        let activeKey;
        let lastItem;
        tempBuildings.forEach((building, index) => {
            if (active?.units_id === building.units_id) activeKey = index;
            lastItem = index;
        });
        activeKey = activeKey + 1;
        if (activeKey > lastItem) {
            activeKey = lastItem;
        }
        setActive(tempBuildings[activeKey]);
        return false;
    });

    let module = "units";
    if (appData?.name === "logBook") {
        module = "logbook";
    }

    useEffect(() => {
        if (authContext.userData && !authContext.getBuildings) {
            async function getBuildings() {
                const response = await axiosProtected().get(
                    module + "/user_unit/" + +authContext.userData.id + "/"
                );
                dispatch({
                    type: "SET_BUILDINGS",
                    payload: {
                        buildings: response.data.units,
                        allBuildings: response.data.allUnits,
                    },
                });
                dispatch({ type: "CLEAR_LOADING" });
                if (response.data.units && response.data?.units?.length === 1) {
                    if (!authContext.building) {
                        setBuilding(response.data.units[0].units_alias);
                    }
                }
                response.data.units === null &&
                    response.data.allUnits.length !== 0 &&
                    setDisplayAll(true);
            }

            getBuildings();
            //setLoading(false);
        }
    }, []);

    return (
        <>
            {!loading ? (
                <div className=" flex-col justify-center max-h-full ">
                    <div className="pb-6">
                        <div className="flex ">
                            <div className="w-full px-4 lg:px-8  ">
                                <div className="mt-4 items-center justify-between ">
                                    <div className=" flex flew-wrap border-b border-gray-300 mb-5 content-center  pb-2 h-14">
                                        <svg
                                            className="text-gray-300 w-7"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                            />
                                        </svg>

                                        <form
                                            onSubmit={handleSubmit(onSubmit)}
                                            className=" inline w-full"
                                        >
                                            <input
                                                {...register("search")}
                                                onChange={search}
                                                onClick={() => {}}
                                                className="px-3 py-3 w-full rounded-lg focus:outline-none border-none focus:border-none focus:ring-0"
                                                type="search"
                                                name="filter"
                                                autoComplete="off"
                                                placeholder="Vyhledávání"
                                            />
                                        </form>
                                    </div>
                                    {authContext.admin && (
                                        <div className="flex justify-end mb-5 ">
                                            <div
                                                className="flex items-center sm:col-span-4 cursor-pointer   w-48"
                                                onClick={() =>
                                                    setDisplayAll(!displayAll)
                                                }
                                            >
                                                <button
                                                    type="button"
                                                    aria-pressed="false"
                                                    aria-labelledby="toggleLabel"
                                                    className={
                                                        (displayAll
                                                            ? "bg-green-600"
                                                            : "bg-gray-200") +
                                                        " relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "
                                                    }
                                                >
                                                    <span className="sr-only">
                                                        všechny objekty
                                                    </span>
                                                    <span
                                                        aria-hidden="true"
                                                        className={
                                                            (displayAll
                                                                ? "translate-x-5"
                                                                : "translate-x-0") +
                                                            " pointer-events-none  inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                        }
                                                    ></span>
                                                </button>
                                                <span
                                                    className="ml-3"
                                                    id="toggleLabel"
                                                >
                                                    <span
                                                        className={
                                                            (displayAll
                                                                ? "text-gray-900 font-medium"
                                                                : "text-gray-400") +
                                                            " text-sm  "
                                                        }
                                                    >
                                                        všechny objekty
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    {authContext.getBuildings &&
                                        !authContext.admin &&
                                        !authContext.buildings &&
                                        !loading && (
                                            <ul
                                                id="gallery"
                                                className="flex flex-1 flex-wrap py-10"
                                            >
                                                <li
                                                    id="empty"
                                                    className="h-full w-full text-center flex flex-col justify-center items-center  "
                                                >
                                                    <ExclamationCircleIcon className="w-16 mx-auto text-gray-300" />

                                                    <span className="text-small text-gray-400">
                                                        Nemáte nastavený žádný
                                                        objekt
                                                    </span>
                                                </li>
                                            </ul>
                                        )}

                                    {tempBuildings &&
                                        tempBuildings.map((building) => (
                                            <button
                                                key={building.units_id}
                                                onClick={() =>
                                                    confirmBuilding(building)
                                                }
                                                className={
                                                    "flex justify-between w-full px-4 py-2  text-sm leading-5 text-left outline-none focus:outline-none  my-2 rounded-md   " +
                                                    (active?.units_id ===
                                                    building.units_id
                                                        ? "bg-green-600 hover:bg-green-700 text-white"
                                                        : "bg-gray-100 hover:bg-gray-200 text-gray-700")
                                                }
                                            >
                                                <div className="flex items-center px-2 py-2 sm:px-6 w-full">
                                                    <div className="min-w-0 flex-1 flex items-center">
                                                        <div className="flex-shrink-0">
                                                            <div className="hidden lg:flex h-12 w-20 overflow-hidden bg-green-200 border-2 text-green-800  rounded-md  flex-wrap content-center justify-center">
                                                                {building.units_img ? (
                                                                    <img
                                                                        className=" object-scale-down w-36"
                                                                        src={
                                                                            "https://admin.stephanos.cz" +
                                                                            building.units_img
                                                                        }
                                                                        alt=""
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <svg
                                                                            className="w-7"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="currentColor"
                                                                            viewBox="0 0 484.738 484.738"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                d="M131.012,438.372h85.624v-87.557h51.467v87.557h85.624V46.366H131.012V438.372z M257.993,92.564h51.466v51.467h-51.466
                                            V92.564z M257.993,175.279h51.466v51.467h-51.466V175.279z M257.993,257.992h51.466v51.467h-51.466V257.992z M175.279,92.564
                                            h51.466v51.467h-51.466V92.564z M175.279,175.279h51.466v51.467h-51.466V175.279z M175.279,257.992h51.466v51.467h-51.466V257.992
                                            z"
                                                                            />
                                                                            <path
                                                                                d="M373.719,81.482V358.66h33.065v79.712h77.954V81.482H373.719z M444.437,321.009H397.58v-46.856h46.856V321.009z
                                            M444.437,245.703H397.58v-46.855h46.856V245.703z M444.437,170.398H397.58v-46.855h46.856V170.398z"
                                                                            />
                                                                            <path
                                                                                d="M0,438.372h77.954V358.66h33.065V81.482H0V438.372z M40.302,123.543h46.856v46.855H40.302V123.543z M40.302,198.848
                                            h46.856v46.855H40.302V198.848z M40.302,274.151h46.856v46.855H40.302V274.151z"
                                                                            />
                                                                        </svg>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="min-w-0 flex-1 px-2 lg:px-4 md:grid  md:gap-4">
                                                            <div>
                                                                <p className="text-base lg:text-xl font-medium">
                                                                    {
                                                                        building.units_name
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg
                                                            className="h-5 w-5 "
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </button>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Loading loading={true} />
            )}
        </>
    );
}
