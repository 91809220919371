import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import HelpDesk from "./HelpDesk";
import LogBook from "./LogBook";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const app = {
    main: { name: "main", title: "", signUp: true },
    logBook: { name: "logBook", title: "Pracovní deník", signUp: false },
    helpDesk: { name: "helpDesk", title: "Helpdesk", signUp: true },
};

const appName = process.env.REACT_APP_NAME;
const appData = app[appName];

if (appName === "helpDesk") {
    ReactDOM.render(
        <HelpDesk appData={appData} />,
        document.getElementById("root")
    );
} else if (appName === "logBook") {
    ReactDOM.render(
        <LogBook appData={appData} />,
        document.getElementById("root")
    );
} else {
    ReactDOM.render(<App appData={appData} />, document.getElementById("root"));
}

serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
