import { useLazyQuery, useQuery } from "@apollo/client";
import { SearchIcon, XIcon } from "@heroicons/react/solid";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { HELPDESK_COUNT, HELPDESK_SEARCH } from "../../query/helpdesk";
import { AuthContext } from "../context/context";
import { HelpdeskItem } from "./helpdeskItem";
import { TopMenu } from "./topMenu";
import { TypeButton } from "./typeButton";

export default function HelpdeskLayout(props) {
    const [authContext, dispatch] = useContext(AuthContext);

    const building = authContext.building;
    const isLoggedIn = authContext?.isLoggedIn;
    const isAdmin = authContext?.admin;
    let userGroup = authContext.building?.group;

    const [searchResults, setSearchResults] = useState([]);
    const [searchShow, setSearchShow] = useState(false);
    const { register, handleSubmit, getValues } = useForm();
    const Title = () => {
        const titleArray = authContext.pageTitle.split(":");
        return (
            <h3 className="text-lg text-gray-500 md:text-2xl mx-auto  border-gray-200 pb-2">
                {titleArray.length === 1 ? (
                    authContext.pageTitle
                ) : (
                    <>
                        {" "}
                        <span className="font-bold">{titleArray[0]}</span>{" "}
                        {titleArray[1]}
                    </>
                )}
            </h3>
        );
    };

    let privateArray = [0];
    if (isAdmin) {
        privateArray = [];
    }

    if (userGroup === "vybor") {
        privateArray = [0, 2];
    }

    const [helpdeskCount, { loading, data }] = useLazyQuery(HELPDESK_COUNT, {
        variables: {
            unitId: parseInt(building?.unit?.units_id),
            vyrizuje: authContext?.userData?._id,
            userId: authContext?.userData?.userId,
            private: privateArray,
        },
        fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
        authContext.isLoggedIn && helpdeskCount();
    }, [authContext.helpdesk.count, authContext.isLoggedIn]);

    const location = useLocation();
    const appData = authContext.appData;
    const searchString = getValues("search") || "";

    const [helpdeskSearch] = useLazyQuery(HELPDESK_SEARCH, {
        variables: {
            search: searchString.length > 2 && searchString.trim(),
            private: privateArray,
            privateAdmin: isAdmin ? null : 1,
            userId: isAdmin ? null : authContext?.userData?.userId,
            unitId: parseInt(building?.unit?.units_id),
        },
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            let results = [];
            let name = data?.name?.collection || [];
            let number = data?.number?.collection || [];
            let privateName = data?.privateName?.collection || [];
            let privateNumber = data?.privateNumber?.collection || [];
            results = Object.assign({}, results, name);
            results = Object.assign({}, results, number);
            results = Object.assign({}, results, privateName);
            results = Object.assign({}, results, privateNumber);

            var merged = Object.keys(results).map((key) => results[key]);
            return setSearchResults(merged);
        },
    });

    async function search() {
        const val = getValues("search").trim();
        if (val.length < 3) {
            await setSearchResults([]);
        } else {
            await helpdeskSearch();
        }
    }

    useEffect(() => {
        dispatch({
            type: "NAVIGATION_ACTIVE",
            payload: {
                active: null,
            },
        });
    }, []);

    useEffect(() => {
        setSearchResults([]);
    }, [location.pathname]);

    const debounce = (func, debounceTimeout) => {
        let timeout;
        return function executedFunction(...args) {
            const delayedFunction = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(delayedFunction, debounceTimeout);
        };
    };

    // eslint-disable-next-line
    const debouncedSubmit = useCallback(
        debounce((e) => {
            search(e);
        }, 500),
        []
    );

    return (
        <>
            <TopMenu module={"helpdesk"} />
            <div className="  bg-white rounded-b-lg shadow ">
                <div
                    className={
                        "w-full px-6 py-3 " +
                        (!authContext.isLoggedIn && "pt-6")
                    }
                >
                    <div className="grid grid-cols-4">
                        <div className="col-span-3 lg:col-span-2 py-2">
                            <Title />
                        </div>
                        {authContext.isLoggedIn && (
                            <>
                                <div className="flex lg:hidden justify-end ">
                                    <button
                                        onClick={() =>
                                            setSearchShow(!searchShow)
                                        }
                                        className="p-2"
                                    >
                                        {searchShow ? (
                                            <XIcon
                                                className={
                                                    "w-6 h-6  text-gray-300"
                                                }
                                            />
                                        ) : (
                                            <SearchIcon
                                                className={
                                                    "w-6 h-6  text-gray-600"
                                                }
                                            />
                                        )}
                                    </button>
                                </div>

                                <div
                                    className={
                                        "justify-end lg:flex lg:col-span-2 " +
                                        (searchShow
                                            ? "col-span-4 flex"
                                            : "hidden")
                                    }
                                >
                                    <div className=" flex w-full lg:w-auto justify-end items-center  relative pb-4 lg:pb-0">
                                        <button
                                            onClick={handleSubmit(
                                                debouncedSubmit
                                            )}
                                            className="absolute  right-2 p-2"
                                        >
                                            <svg
                                                className="text-gray-300 w-5 h-5 "
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                />
                                            </svg>
                                        </button>
                                        <form
                                            onSubmit={handleSubmit(
                                                debouncedSubmit
                                            )}
                                            className=" inline w-full lg:w-auto "
                                        >
                                            <input
                                                {...register("search")}
                                                onInput={() =>
                                                    debouncedSubmit()
                                                }
                                                className="px-6 py-2 w-full lg:w-auto  pr-14 rounded-lg focus:outline-none border border-gray-400 focus:border-green-600 focus:ring-1 focus:ring-green-600 "
                                                type="search"
                                                autoComplete="off"
                                                placeholder="Vyhledávání"
                                            />
                                        </form>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {searchResults && searchResults.length > 0 && (
                            <>
                                <div className="my-2 rounded-md bg-gray-100">
                                    {/* <div>
                                    <button
                                        onClick={() => setSearchResults([])}
                                    >
                                        {" "}
                                        Vymazat výsledky
                                    </button>
                                </div> */}
                                    {searchResults?.map((item, itemIdx) => (
                                        <HelpdeskItem
                                            key={itemIdx}
                                            item={item}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div>
                    <div>
                        <div className="lg:divide-y lg:divide-gray-200 lg:col-span-9">
                            {building?.unit && isLoggedIn && (
                                <>
                                    <nav className="bg-white lg:shadow">
                                        <div className="   ">
                                            <div className="relative flex justify-between  ">
                                                <div className="flex-1 flex w-full items-stretch justify-between">
                                                    <div className=" w-full overflow-x-auto no-scrollbar flex  lg:py-0 px-5 space-x-2  lg:space-x-4">
                                                        <TypeButton
                                                            to={"/helpdesk"}
                                                            title={"Aktivní"}
                                                            type={"aktivni"}
                                                            count={
                                                                data?.active
                                                                    ?.paginationInfo
                                                                    ?.totalCount
                                                            }
                                                        />
                                                        <TypeButton
                                                            to={
                                                                "/helpdesk/vyresene"
                                                            }
                                                            title={"Vyřešené"}
                                                            type={"vyresene"}
                                                            count={
                                                                data?.done
                                                                    ?.paginationInfo
                                                                    ?.totalCount
                                                            }
                                                        />

                                                        {data?.postponed
                                                            ?.paginationInfo
                                                            ?.totalCount >
                                                            0 && (
                                                            <TypeButton
                                                                to={
                                                                    "/helpdesk/odlozene"
                                                                }
                                                                title={
                                                                    "Odložené"
                                                                }
                                                                type={
                                                                    "odlozene"
                                                                }
                                                                count={
                                                                    data
                                                                        ?.postponed
                                                                        ?.paginationInfo
                                                                        ?.totalCount
                                                                }
                                                            />
                                                        )}
                                                        <TypeButton
                                                            to={
                                                                "/helpdesk/moje"
                                                            }
                                                            title={
                                                                isAdmin
                                                                    ? "Moje"
                                                                    : "Soukromé"
                                                            }
                                                            type={"moje"}
                                                            count={
                                                                isAdmin
                                                                    ? data?.my
                                                                          ?.paginationInfo
                                                                          ?.totalCount
                                                                    : data
                                                                          ?.private
                                                                          ?.paginationInfo
                                                                          ?.totalCount
                                                            }
                                                        />
                                                        <div className="hidden lg:flex h-full ">
                                                            <TypeButton
                                                                to={
                                                                    "/helpdesk/novy"
                                                                }
                                                                title={
                                                                    "Nový požadavek"
                                                                }
                                                                type={"novy"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </>
                            )}

                            <div className="px-8 py-5">{props.children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
