export const BuildingSelect = (props) => {
    const building = props.building;
    const buildings = props.buildings;
    const selectBuilding = props.selectBuilding;

    return (
        <button
            type="button"
            onClick={() => selectBuilding(buildings)}
            className="  h-12 font-medium text-green-100 bg-white bg-opacity-10  w-full rounded-md flex items-center text-sm focus:outline-none  lg:p-2   lg:hover:bg-green-800"
        >
            <div className="flex justify-center w-full items-center">
                <div className="  mx-2 hidden lg:block">
                    <div className="h-8 w-8 bg-green-200 border-2 text-green-800  rounded-full flex flex-wrap content-center justify-center">
                        <svg
                            className="w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 484.738 484.738"
                            stroke="currentColor"
                        >
                            <path
                                d="M131.012,438.372h85.624v-87.557h51.467v87.557h85.624V46.366H131.012V438.372z M257.993,92.564h51.466v51.467h-51.466
                  V92.564z M257.993,175.279h51.466v51.467h-51.466V175.279z M257.993,257.992h51.466v51.467h-51.466V257.992z M175.279,92.564
                  h51.466v51.467h-51.466V92.564z M175.279,175.279h51.466v51.467h-51.466V175.279z M175.279,257.992h51.466v51.467h-51.466V257.992
                  z"
                            />
                            <path
                                d="M373.719,81.482V358.66h33.065v79.712h77.954V81.482H373.719z M444.437,321.009H397.58v-46.856h46.856V321.009z
                  M444.437,245.703H397.58v-46.855h46.856V245.703z M444.437,170.398H397.58v-46.855h46.856V170.398z"
                            />
                            <path
                                d="M0,438.372h77.954V358.66h33.065V81.482H0V438.372z M40.302,123.543h46.856v46.855H40.302V123.543z M40.302,198.848
                  h46.856v46.855H40.302V198.848z M40.302,274.151h46.856v46.855H40.302V274.151z"
                            />
                        </svg>
                    </div>
                </div>
                <span className="truncate mx-3">
                    {building && building.unit ? (
                        building.unit.units_name
                    ) : (
                        <>Vyberte objekt</>
                    )}
                </span>
                <svg
                    className="flex-shrink-0 ml-1 h-5 w-5 "
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
        </button>
    );
};

export default BuildingSelect;
