import { gql } from "@apollo/client";

export const SALDO = gql`
    query saldo($unitId: Int!) {
        saldos(unitId: $unitId) {
            name
            flat
            topay
            type
            paid
            inserted
            sum
        }
        saldoSummaries(unitId: $unitId) {
            nedoplatek
            preplatek
            inserted
        }
    }
`;

export const SALDO_USER = gql`
    query saldo($unitId: Int!, $usersDataId: Int!) {
        saldos(unitId: $unitId, usersDataId: $usersDataId) {
            name
            flat
            topay
            type
            inserted
            paid
            sum
        }
    }
`;
