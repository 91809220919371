import { useState, useEffect } from "react";
import { Error } from "../form/Error";
import { AddressSelect } from "./AddressSelect";

export const Address = (props) => {
    const register = props.register;
    const errors = props.errors;
    let formValues;
    const setValue = props.setValue;
    const addressData = props.addressData;

    const [disabled] = useState();
    const [degree, setDegree] = useState();
    const [selectedAddress, setAddress] = useState();
    const [userData] = useState(JSON.parse(localStorage.getItem("userData")));

    const [selectedType] = useState("personal");

    // useEffect(() => {
    //     formValues && Object.keys(formValues).map((key) => {
    //         setValue(key,formValues[key])
    //     })
    //     if(formValues?.addressId) {
    //       setValue('addressId',formValues.addressId)
    //     }
    //     if(data?.email) {
    //       setValue('email',data.email)
    //       setDisabled(true)
    //     } else {
    //       setDisabled(false)
    //     }
    //   }
    //   // eslint-disable-next-line
    //   , [data]);

    useEffect(
        () => {
            if (addressData && addressData[selectedAddress]) {
                Object.keys(addressData[selectedAddress]).forEach((key) => {
                    setValue(key, addressData[selectedAddress][key]);
                });
                setValue("email", userData?.email);
                setValue("addressId", addressData[selectedAddress].id);
            } else if (!formValues?.addressId) {
                setValue("street");
                setValue("city");
                setValue("zip");
                setValue("addressId");
            }
        },
        // eslint-disable-next-line
        [selectedAddress]
    );

    useEffect(
        () => {
            if (!selectedAddress && addressData) {
                setAddress(0);
            }
        },
        // eslint-disable-next-line
        [addressData]
    );

    return (
        <>
            {/* <div className="space-x-2 flex ">
        <TypeButton type={'personal'}>
                <svg className="w-5 mx-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
            Osobní
        </TypeButton>
        <TypeButton type={'business'}>
            
            <svg className="w-5 mx-2" fill="currentColor" viewBox="0 -31 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="m497.09375 60.003906c-.03125 0-.0625-.003906-.09375-.003906h-136v-15c0-24.8125-20.1875-45-45-45h-120c-24.8125 0-45 20.1875-45 45v15h-136c-8.351562 0-15 6.84375-15 15v330c0 24.8125 20.1875 45 
                45 45h422c24.8125 0 45-20.1875 45-45v-329.683594c0-.019531 0-.039062 0-.058594-.574219-9.851562-6.632812-15.199218-14.90625-15.253906zm-316.09375-15.003906c0-8.269531 6.730469-15 15-15h120c8.269531 
                0 15 6.730469 15 15v15h-150zm295.1875 45-46.582031 139.742188c-2.042969 6.136718-7.761719 10.257812-14.226563 10.257812h-84.378906v-15c0-8.285156-6.714844-15-15-15h-120c-8.285156 0-15 6.714844-15 
                15v15h-84.378906c-6.464844 0-12.183594-4.121094-14.226563-10.257812l-46.582031-139.742188zm-175.1875 150v30h-90v-30zm181 165c0 8.269531-6.730469 15-15 15h-422c-8.269531 0-15-6.730469-15-15v-237.566406l23.933594 
                71.796875c6.132812 18.40625 23.289062 30.769531 42.6875 30.769531h84.378906v15c0 8.285156 6.714844 15 15 15h120c8.285156 0 15-6.714844 15-15v-15h84.378906c19.398438 0 36.554688-12.363281 
                42.6875-30.769531l23.933594-71.796875zm0 0"/>
            </svg>
        Firemní
        </TypeButton>
         
    </div> */}

            <input type="hidden" {...register("addressId")} />
            {addressData && addressData.length > 0 && (
                <div className="relative   z-10 mb-3">
                    <AddressSelect
                        addressData={addressData}
                        selectedAddress={selectedAddress}
                        setAddress={setAddress}
                    />
                </div>
            )}
            <div className=" grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 inset-0 relative ">
                {selectedType === "personal" ? (
                    <>
                        <div className="sm:col-span-3">
                            <label
                                htmlFor="first_name"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Jméno
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    id="first_name"
                                    {...register("fname", { required: true })}
                                    className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors?.fname && (
                                    <Error text="Vyplňte jméno" />
                                )}
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label
                                htmlFor="last_name"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Příjmení
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="surname"
                                    id="last_name"
                                    {...register("surname", { required: true })}
                                    className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors?.surname && (
                                    <Error text="Vyplňte příjmení" />
                                )}
                            </div>
                        </div>

                        {degree && (
                            <>
                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="pre"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Titul před jménem
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="pre"
                                            id="pre"
                                            {...register("pre")}
                                            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="post"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Titul za jménem
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="post"
                                            id="post"
                                            {...register("post")}
                                            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="sm:col-span-6 flex justify-end text-green-500 absolute right-0">
                            {degree ? (
                                <button
                                    type="button"
                                    onClick={() => setDegree(!degree)}
                                    className="text-sm font-medium focus:outline-none flex items-center"
                                >
                                    <svg
                                        className="inline h-5 mr-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>{" "}
                                    Skrýt titul
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    onClick={() => setDegree(!degree)}
                                    className="text-sm font-medium focus:outline-none flex items-center "
                                >
                                    <svg
                                        className="inline h-5 mr-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>{" "}
                                    Přidat titul
                                </button>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="sm:col-span-3">
                            <label
                                htmlFor="company"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Název firmy
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="company"
                                    id="company"
                                    {...register("company")}
                                    className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors?.fname && (
                                    <Error text="Vyplňte název firmy" />
                                )}
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label
                                htmlFor="inum"
                                className="block text-sm font-medium text-gray-700"
                            >
                                IČ
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="inum"
                                    id="inum"
                                    {...register("inum")}
                                    className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors?.surname && <Error text="Vyplňte IČ" />}
                            </div>
                        </div>
                    </>
                )}

                <div className="sm:col-span-4">
                    <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                    >
                        E-mail
                    </label>
                    <div className="mt-1 relative">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            {...register("email", { required: true })}
                            className={
                                "shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md " +
                                (disabled ? "bg-gray-200" : "")
                            }
                        />

                        {errors?.email && <Error text="Vyplňte e-mail" />}
                    </div>
                </div>
                <div className="sm:col-span-2">
                    <label
                        htmlFor="tel"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Telefon
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="tel"
                            id="tel"
                            {...register("tel", { required: true })}
                            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors?.tel && <Error text="Vyplňte telefon" />}
                    </div>
                </div>
                <div className="sm:col-span-6">
                    <label
                        htmlFor="street"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Ulice, číslo domu
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="street"
                            id="street"
                            {...register("street", { required: true })}
                            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors?.street && <Error text="Vyplňte ulici" />}
                    </div>
                </div>

                <div className="sm:col-span-4">
                    <label
                        htmlFor="city"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Město
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="city"
                            id="city"
                            {...register("city", { required: true })}
                            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors?.city && <Error text="Vyplňte město" />}
                    </div>
                </div>

                <div className="sm:col-span-2">
                    <label
                        htmlFor="last_name"
                        className="block text-sm font-medium text-gray-700"
                    >
                        PSČ
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="zip"
                            id="zip"
                            {...register("zip", { required: true })}
                            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors?.zip && <Error text="Vyplňte PSČ" />}
                    </div>
                </div>
            </div>
        </>
    );
};
