import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../context/context";

export const TypeButton = ({
    title,
    to,
    count,
    main,
    active,
    type,
    module,
}) => {
    const [authContext, dispatch] = useContext(AuthContext);

    let activeClassName = main
        ? module === "logbook" && !type
            ? ""
            : "bg-green-600 text-white divide-white "
        : "bg-green-600 text-white lg:bg-white lg:text-gray-900 lg:border-b-green-500 divide-white font-bold ";

    function setActive() {
        dispatch({
            type: "NAVIGATION_ACTIVE",
            payload: {
                active: type,
            },
        });
    }

    return (
        <>
            <NavLink
                to={to}
                onClick={setActive}
                className={({ isActive }) =>
                    main
                        ? "  text-gray-900 inline-flex items-center px-4  py-2  border border-gray-400 rounded-md   hover:border-b-green-500  text-sm font-medium " +
                          (isActive && activeClassName)
                        : "lg:border-transparent text-gray-900 inline-flex items-center px-4 lg:px-2 py-2 lg:py-5 divide-x lg:divide-x-0 divide-gray-500 border border-gray-400 rounded-md lg:rounded-none hover:border-b-green-500  lg:border-b-2 text-sm font-medium " +
                          ((isActive ||
                              active ||
                              type === authContext?.navigation?.active) &&
                              activeClassName)
                }
                end={to === "/helpdesk" && !main ? true : false}
            >
                <span className={"  " + (count && "pr-3")}>{title}</span>
                {count > 0 && (
                    <span className="pl-3 lg:pl-3  lg:py-0.5 lg:px-3 lg:text-xs lg:rounded-full lg:bg-gray-200 group-hover:bg-gray-400">
                        {count}
                    </span>
                )}
            </NavLink>
        </>
    );
};
