import { format } from "date-fns";
import React from "react";
import { NavLink } from "react-router-dom";

function toHoursAndMinutes(totalMinutes) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    let result;
    if (hours > 0) {
        result = hours + " h ";
    }
    if (minutes > 0) {
        result = result + minutes + " min ";
    }
    return result;
}

export const LogbookItem = ({ item, typ, appData }) => {
    const inserted = item.vlozeno && format(new Date(item.vlozeno), "d. M. y");
    const done =
        item.lastUpdate && format(new Date(item.lastUpdate), "d. M. y");

    return (
        <>
            <NavLink
                key={item.id}
                to={
                    appData.name === "logBook" || appData.name === "helpDesk"
                        ? "/denik/zaznam/" + item.number
                        : "/helpdesk/denik/zaznam/" + item.number
                }
                className="py-4 flex items-center justify-between hover:bg-gray-50 p-3 rounded-md "
            >
                <div className="grid grid-cols-3 lg:grid-cols-12 text-sm w-full gap-4 items-center">
                    <div className="">
                        <span
                            className={
                                "font-medium " +
                                (item.done === "0" && "text-green-600")
                            }
                        >
                            {item.number}
                        </span>
                    </div>

                    <div className="col-span-2 ">
                        <span className="flex items-start w-full justify-center">
                            <svg
                                className="w-4 mr-1 pt-1 inline text-gray-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                />
                            </svg>
                            {inserted}
                        </span>
                    </div>
                    <div className="col-span-4 text-base lg:text-sm order-first lg:order-none">
                        <h2 className="  font-medium text-gray-900 truncate ">
                            {item.nazev}
                        </h2>
                    </div>
                    <div className="lg:col-span-2 text-center">
                        {item?.userData?.userData && (
                            <span>
                                {item?.userData?.userData?.fname +
                                    " " +
                                    item?.userData?.userData?.surname}
                            </span>
                        )}
                    </div>

                    <div className="col-span-2 justify-end ">
                        {toHoursAndMinutes(item.minutes)}
                    </div>
                </div>
            </NavLink>
        </>
    );
};
