import React, { useState, useEffect, useContext } from "react";
import UploadService from "../services/fileUploadService";
import { AuthContext } from "./context/context";
import { Error } from "./form/form";
import { Info } from "./layout/Info";

const UploadFiles = ({ uploadId, module }) => {
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const [fileInfos, setFileInfos] = useState([]);

    const [authContext, dispatch] = useContext(AuthContext);

    useEffect(() => {
        uploadId &&
            UploadService.getFiles(uploadId).then((response) => {
                setFileInfos(response.data);
            });
    }, [uploadId]);

    const deleteFile = (fileId) => {
        UploadService.deleteFile(fileId, uploadId).then((response) => {
            setFileInfos(response.data);
        });
    };

    function setModal(img) {
        dispatch({
            type: "SET_MODAL",
            payload: {
                component: Info,
                params: {
                    title: "",
                    content: img,
                },
            },
        });
    }

    const upload = (event) => {
        for (let i = 0; i < event.target.files.length; i++) {
            let currentFile = event.target.files[i];
            setProgress(0);
            setCurrentFile(currentFile);

            UploadService.upload(
                currentFile,
                uploadId,
                (event) => {
                    setProgress(Math.round((100 * event.loaded) / event.total));
                },
                module
            )
                .then((response) => {
                    setMessage(response.data.message);
                    return UploadService.getFiles(uploadId);
                })
                .then((files) => {
                    setFileInfos(files.data);
                    setCurrentFile(undefined);
                })
                .catch(() => {
                    setProgress(0);
                    setMessage("Soubor se nepodařilo nahrát");
                });
        }
    };

    return (
        <div>
            <div
                className={
                    "mb-5 mt-2 border-2 border-dashed rounded border-gray-300 hover:border-green-500 relative text-gray-500 hover:text-gray-800"
                }
            >
                {currentFile && (
                    <div className="progress p-5 z-50 absolute w-full top-1/2 -mt-2 pt-0 right-0">
                        <div className="h-5 relative max-w-xl rounded overflow-hidden shadow-md">
                            <div className="w-full h-full bg-gray-200 absolute"></div>
                            <div
                                className="h-full bg-green-500 absolute"
                                role="progressbar"
                                style={{ width: progress + "%" }}
                                aria-valuenow={progress}
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                    </div>
                )}
                <div className={"" + (currentFile ? "opacity-30" : "")}>
                    <input
                        type="file"
                        multiple
                        onChange={(e) => upload(e)}
                        className="cursor-pointer relative block opacity-0 w-full h-full p-10 z-50"
                    />
                    <div className="text-center p-10 pt-4 absolute top-0 right-0 left-0 m-auto">
                        <svg
                            className="inline  h-8 w-8 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                        >
                            <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <h4>
                            <button className="bg-gray-200 py-1 px-4  rounded hover:bg-gray-300">
                                vyberte soubory
                            </button>
                            <span className="hidden md:inline ml-2">
                                nebo je přetáhněte sem
                            </span>
                        </h4>
                    </div>
                </div>
            </div>

            <div className="alert alert-light" role="alert">
                {message}
            </div>
            {fileInfos && fileInfos.length > 0 && (
                <div className="card">
                    <div className="text-sm font-medium text-gray-700">
                        Seznam příloh
                    </div>

                    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                        {fileInfos.map((file, index) => {
                            return (
                                <li
                                    key={index}
                                    className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 relative"
                                >
                                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                                        <div className="flex items-center space-x-2 overflow-hidden">
                                            <div className="flex-shrink-0"></div>
                                            <div className="flex-1 min-w-0  ">
                                                {file.files_type === "img" ? (
                                                    <img
                                                        src={
                                                            file.files_filename
                                                        }
                                                        alt={file.files_name}
                                                        className="cursor-pointer"
                                                        onClick={() =>
                                                            setModal(
                                                                <img
                                                                    src={
                                                                        file.files_filename
                                                                    }
                                                                    alt={
                                                                        file.files_name
                                                                    }
                                                                    className="cursor-pointer"
                                                                />
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <p className="text-sm font-medium text-gray-900 truncate">
                                                        {file.files_name}.
                                                        {file.files_ext}
                                                    </p>
                                                )}
                                            </div>
                                            <div>
                                                <button
                                                    type="button"
                                                    data-id={file.files_id}
                                                    onClick={() =>
                                                        deleteFile(
                                                            file.files_id
                                                        )
                                                    }
                                                    className="absolute right-2 top-2 focus:outline-none inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-500 bg-white hover:bg-green-500 hover:text-white"
                                                >
                                                    <svg
                                                        className="h-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M6 18L18 6M6 6l12 12"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    {message && <Error text={message} />}
                </div>
            )}
        </div>
    );
};

export default UploadFiles;
