import { useCallback, useEffect, useReducer, useState } from "react";
import { Reducer } from "./reducers";
import { AuthContext } from "./context";
import { tokenExpiration } from "../../services/tokenExpiration";
import { refreshToken } from "../../services/refreshToken";
import axiosInstance from "../../client";

export const AuthContextProvider = (props) => {
    const [token, setToken] = useState(
        JSON.parse(localStorage.getItem("token"))
    );

    const [building, setBuilding] = useState(
        JSON.parse(localStorage.getItem("building"))
    );

    const [logbookTypes, setLogbookTypes] = useState(
        JSON.parse(localStorage.getItem("logbookTypes"))
    );

    const login = useCallback((data) => {
        const token = JSON.parse(localStorage.getItem("token"));
        setToken(token);
        let userData;
        let admin = false;
        if (data) {
            userData = {
                ...data.userData,
                id: data.userData._id,
                userId: parseInt(data._id),
                groups: data.groups,
            };
            localStorage.setItem("userData", JSON.stringify(userData));
            if (data?.groups) {
                data?.groups.forEach((group) => {
                    if (
                        group.code === "admin" ||
                        group.code === "superadmin" ||
                        group.code === "spravce"
                    ) {
                        admin = true;
                    }
                });
            }
        } else {
            userData = JSON.parse(localStorage.getItem("userData"));
            if (userData?.groups) {
                userData?.groups.forEach((group) => {
                    if (
                        group.code === "admin" ||
                        group.code === "superadmin" ||
                        group.code === "spravce"
                    ) {
                        admin = true;
                    }
                });
            }
        }

        dispatch({
            type: "SET_USERDATA",
            payload: {
                userData: userData,
            },
        });
        if (admin) {
            dispatch({
                type: "SET_ADMIN",
            });
        }
        dispatch({
            type: "SET_USER",
            payload: {
                data: userData,
                token: token,
            },
        });
    }, []);

    const logout = useCallback(() => {
        setToken(null);
        localStorage.removeItem("userData");
        localStorage.removeItem("building");
        localStorage.removeItem("token");
        dispatch({ type: "CLEAR_USER" });
    }, []);

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("token"));
        const storedData = JSON.parse(localStorage.getItem("userData"));
        if (storedData && token) {
            login();
        }
    }, [login]);

    useEffect(() => {
        tokenExpiration(logout);
    }, [token, logout]);

    useEffect(() => {
        if (token) {
            const remainingTime =
                new Date(token.expiration).getTime() - new Date().getTime();
            if (remainingTime < 10000) {
                refreshToken(logout);
            }
        }
        if (token) {
            async function token() {
                await tokenExpiration(logout);
            }
            token();
        }
    }, [login]);

    useEffect(() => {
        !logbookTypes &&
            axiosInstance
                .get("?e=logbook_types")
                .then((res) => {
                    localStorage.setItem(
                        "logbookTypes",
                        JSON.stringify(res.data)
                    );
                    setLogbookTypes(res.data);
                    dispatch({
                        type: "SET_TYPES",
                        payload: {
                            types: res.data,
                        },
                    });
                })
                .catch((err) => {
                    console.log(err);
                });

        //eslint-disable-next-line
    }, []);

    const initialState = {
        isLoggedIn: !!token,
        token: token,
        login: login,
        logout: logout,
        appData: props.appData,
        building: building,
        userGroup: null,
        userData: null,
        buildings: [],
        allBuildings: [],
        modal: { open: false },
        admin: false,
        loading: false,
        pageTitle: props.appData.title,
        helpdesk: {
            count: 0,
        },
        logbook: {
            count: 0,
            types: logbookTypes,
        },
        navigation: {
            active: null,
        },
    };

    const [state, dispatch] = useReducer(Reducer, initialState);

    return (
        <AuthContext.Provider value={[state, dispatch]}>
            {props.children}
        </AuthContext.Provider>
    );
};
